import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { isApparelProduct } from 'src/util/product/productTypeIdsHelper';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { setEditorBackgroundColorAction } from 'src/store/editor/slice';
import { APPAREL_EDITOR_BACKGROUND } from 'src/settings/settings';

const reflectProductSpecificSettingsOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  if (isApparelProduct(useIntegrationLayer().productTypeId || 1)) {
    dispatch(setEditorBackgroundColorAction(APPAREL_EDITOR_BACKGROUND));
  }
};

export default reflectProductSpecificSettingsOperation;
