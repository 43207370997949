import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';

const sendEditorUISettingsPostMessageOperiation = (callback?: any) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  sendPostMessage({
    type: MESSAGE_TYPE['editor.setEditorHostSettings'],
    data: {
      userInterface: {
        fixedMobileBottomBar: true,
      },
    },
    callback,
  });
};

export default sendEditorUISettingsPostMessageOperiation;
