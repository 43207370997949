import sendRequestDesignDataPostMessageOperation from 'src/store/app/operation/sendRequestDesignDataPostMessageOperation';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { AppDispatch } from 'src/store/index';

const sendRequestDesignDataPostMessageToAddToBasketOperation = () => (dispatch: AppDispatch) => {
  const callbackMessage = {
    type: MESSAGE_TYPE['host.addToBasket'],
  };
  dispatch(sendRequestDesignDataPostMessageOperation(callbackMessage, true));
};

export default sendRequestDesignDataPostMessageToAddToBasketOperation;
