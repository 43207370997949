import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import preflightApiRequest from 'src/api/preflightApiRequest';
import preFlightEndpoints from 'src/api/preFlightEndpoints';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import getProductUid from 'src/store/design/selector/getProductUid';
import getProductDimensions from 'src/store/editor/selector/getProductDimensions';
import { Store } from 'src/store/index';
import dataLayerPush from 'src/util/analytics/dataLayer';
import getCategoryId from '../selector/getCategoryId';
import getInternalUid from '../selector/getInternalUid';
import getProductId from '../selector/getProductId';
import { setDesignIsLoadingAction } from '../slice';
import { DesignData } from '../types';

const updateProductDimensionPostMessageOperation = ({ width, height }: {
  width: number;
  height: number;
}) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const internalUid = getInternalUid(store);
  const productUid = getProductUid(store);
  const productId = getProductId(store);
  const categoryId = getCategoryId(store);
  const productDimensions = getProductDimensions(store) as any;
  const newDimensions = { width, height };

  if (productDimensions.width === width && productDimensions.height === height) {
    return;
  }

  dataLayerPush('apiEditorProductDimensionChanged', newDimensions);

  dispatch(setDesignIsLoadingAction(true));

  const designTemplate = await preflightApiRequest(
    preFlightEndpoints.PRODUCT_TEMPLATE,
    {
      product_uid: productUid,
      type: 'json',
      custom_width: width,
      custom_height: height,
    },
    'data',
  ) as DesignData;

  if (typeof designTemplate !== 'object') {
    dispatch(setDesignIsLoadingAction(false));
    return;
  }

  const callback = {
    type: MESSAGE_TYPE['product.productUidChanged'],
    data: {
      productUid,
      internalUid,
      productId,
      categoryId,
      ...newDimensions,
    },
  };

  sendPostMessage({
    type: MESSAGE_TYPE['design.changeFormat'],
    data: {
      productUid,
      forceFormatChange: true,
      designTemplate,
    },
    callback,
  });
};

export default updateProductDimensionPostMessageOperation;
