import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerOval from 'src/component/Icon/SpinnerOval/SpinnerOval';
import Popup from 'src/component/Popup';
import trackEventOperation from 'src/store/addon/operation/trackEventOperation';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import sendRequestDesignDataPostMessageToAddToBasketOperation from 'src/store/app/operation/sendRequestDesignDataPostMessageToAddToBasketOperation';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import { registerCustomerAction } from 'src/store/app/slice';
import DangerIcon from '../Icon/DangerIcon';
import styles from './index.module.scss';

const EmptyDesignWarningPopup = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onContinueEditing = () => {
    dispatch(hidePopupOperation());
    dispatch(registerCustomerAction('EmptyDesignWarningPopupContinuedEditing'));
    dispatch(trackEventOperation('EmptyDesignWarningPopupContinuedEditing'));
  };

  const onAddToBasket = () => {
    setIsLoading(true);
    dispatch(registerCustomerAction('EmptyDesignWarningPopupAddedToBasket'));
    dispatch(trackEventOperation('EmptyDesignWarningPopupAddedToBasket'));
    dispatch(sendRequestDesignDataPostMessageToAddToBasketOperation());
    setTimeout(() => setIsLoading(false), 30000);
  };

  return (
    <div className={styles.popupWrapper}>
      <Popup popupId="EmptyDesignWarningPopup" title="">
        <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
          <div className={styles.warning}>
            <DangerIcon />
            <span className={styles.warning__message}>{t('txt_warning')}</span>
          </div>
          <div className={styles.message}>
            <div className={styles.message__info}>{t('txt_empty_placeholders')}</div>
            <div className={styles.message__question}>{t('txt_please_add_image')}</div>
          </div>
          <div className={styles.actions}>
            {isLoading ? (
              <SpinnerOval />
            ) : (
              <>
                <button disabled={isLoading} className={cn(styles.action, styles['action--primary'])} onClick={onContinueEditing}>
                  {t('txt_apparel_editor_button_blank_pop_up_continue_editing')}
                </button>
                <button disabled={isLoading} className={cn(styles.action, styles['action--secondary'])} onClick={onAddToBasket}>
                  {t('opeh_top-bar_add-to-basket')}
                </button>
              </>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default EmptyDesignWarningPopup;
