import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';

const sendUploaderUploadSuccessPostMessageOperation = (id: string, url: string, width?: number | null, height?: number | null) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => sendPostMessage({
  type: MESSAGE_TYPE['uploader.uploadSuccess'],
  data: {
    file: {
      id,
      width,
      height,
    },
    url,
  },
});

export default sendUploaderUploadSuccessPostMessageOperation;
