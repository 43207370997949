import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import clipArtCategoryToAddonCategory from 'src/util/addon/clipArtCategoryToAddonCategory';
import getPrice from 'src/store/price/selector/getPrice';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import formatPrice from 'src/util/price/formatPrice';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import getClipArtCategory from '../selector/getClipArtCategory';
import getClipartSearchString from '../selector/getClipartSearchString';

const sendClipArtCategoryPostMessageOperation = (clipArtCategoryId = 0) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const clipArtCategory = getClipArtCategory(store, clipArtCategoryId);
  const categoryId = getCategoryId(store);

  const currencyId = getCurrencyId(store) || '';
  const priceFormat = getPriceFormat(store) || '';

  const getAddonPrice = (productId: number) => {
    const priceObj = getPrice(store, categoryId || 1, productId, 1);
    if (!priceObj) {
      return '';
    }
    return formatPrice(productPriceToPrice(priceObj).price, priceFormat, currencyId);
  };

  if (clipArtCategory) {
    sendPostMessage({
      type: MESSAGE_TYPE['addons.setAddons'],
      data: {
        search: {
          value: getClipartSearchString(store),
          recent: [],
        },
        categoryId: clipArtCategory.parentClipArtCategoryId || 0,
        resultsVersion: 0,
        results: {
          category: clipArtCategoryToAddonCategory(
            clipArtCategory,
            clipArtCategory.parentClipArtCategoryId || 0,
            getAddonPrice,
          ),
          elements: undefined,
        },
      },
    });
  }
};

export default sendClipArtCategoryPostMessageOperation;
