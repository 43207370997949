import gtmSendEvent from './gtmSendEvent';

const DATALAYER_PUSH_TIMEOUT = 10000;

interface FunnelStep {
  name: string;
  hashTag: string;
}
interface FunnelSteps {
  [key: string]: FunnelStep;
}

export const FUNNEL_STEPS: FunnelSteps = {
  landing: {
    name: 'landing',
    hashTag: '/funnel/product/landing',
  },
  category: {
    name: 'category',
    hashTag: '/funnel/product/category',
  },
  searchResults: {
    name: 'category-search-results',
    hashTag: '/funnel/product/category-search-results',
  },
  productPageSearchResult: {
    name: 'product-page-search-result',
    hashTag: '/funnel/product/product-page-search-result',
  },
  productPage: {
    name: 'product-page',
    hashTag: '/funnel/product/product-page',
  },
  editor: {
    name: 'editor',
    hashTag: '/funnel/design/edit',
  },
  basket: {
    name: 'basket',
    hashTag: 'funnel/order/my_cart',
  },
  postPurchase: {
    name: 'post-purchase',
    hashTag: 'funnel/order/payment_success',
  },
};

interface FunnelStepEvent {
  funnelStep: FunnelStep;
  geFunnelStep: string;
}

const gtmSendFunnelStepEvent = (funnelStep: FunnelStep, extraTag = '', extraProps: any = {}) => new Promise((resolve) => {
  if (!window.dataLayer || !window.dataLayer.length) {
    resolve(null);
    return;
  }

  const [event] = window?.dataLayer as FunnelStepEvent[];
  if (!event.funnelStep) {
    resolve(null);
    return;
  }

  const funnelStepHashData = `${funnelStep.hashTag}${extraTag}`;
  const gaFunnelStep = `${window.location.href}#${funnelStepHashData}`;
  if (event.geFunnelStep === gaFunnelStep) {
    resolve(null);
    return;
  }

  const [lastEvent] = window.dataLayer.filter((ev) => ev.funnelStep).reverse();
  if (lastEvent.gaFunnelStep === gaFunnelStep) {
    resolve(null);
    return;
  }

  const timeOutId = setTimeout(resolve, DATALAYER_PUSH_TIMEOUT);

  gtmSendEvent({
    ...event,
    ...extraProps,
    eventCallback: () => {
      clearTimeout(timeOutId);
      resolve(null);
    },
    funnelStep: funnelStep.name,
    gaFunnelStep,
  });
});

export default gtmSendFunnelStepEvent;
