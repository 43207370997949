import { combineReducers } from 'redux';
import app, { appInitialState } from 'src/store/app/slice';
import { AppState } from 'src/store/app/types';
import design, { designInitialState } from 'src/store/design/slice';
import { DesignState } from 'src/store/design/types';
import editor, { editorInitialState } from 'src/store/editor/slice';
import { EditorState } from 'src/store/editor/types';
import format, { formatInitialState } from 'src/store/format/slice';
import { FormatState } from 'src/store/format/types';
import gallery, { galleryInitialState } from 'src/store/gallery/slice';
import price, { priceInitialState } from 'src/store/price/slice';
import { PriceState } from 'src/store/price/types';
import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import productBundle, { productBundleInitialState } from 'src/store/productBundle/slice';
import { ProductBundleState } from 'src/store/productBundle/types';
import template, { templateInitialState } from 'src/store/template/slice';
import { TemplateState } from 'src/store/template/types';
import { AddonState } from 'src/store/addon/types';
import addon, { addonInitialState } from 'src/store/addon/slice';
import upsell, { upsellInitialState } from 'src/store/upsell/slice';
import { UpsellState } from 'src/store/upsell/types';
import { GalleryState } from './gallery/types';

export interface Store {
  app: AppState;
  addon: AddonState;
  design: DesignState;
  gallery: GalleryState;
  price: PriceState;
  template: TemplateState;
  format: FormatState;
  editor: EditorState;
  upsell: UpsellState;
  productBundle: ProductBundleState;
}

export const rootReducer = combineReducers({
  app,
  addon,
  design,
  price,
  template,
  gallery,
  format,
  editor,
  upsell,
  productBundle,
});

export type RootState = ReturnType<typeof rootReducer>;

export const initState: Store = {
  app: appInitialState,
  addon: addonInitialState,
  design: designInitialState,
  gallery: galleryInitialState,
  price: priceInitialState,
  template: templateInitialState,
  format: formatInitialState,
  editor: editorInitialState,
  upsell: upsellInitialState,
  productBundle: productBundleInitialState,
};

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store;
