import { mugProductTypeIds } from 'src/api/productTypes';
import { Store } from 'src/store';
import { Feature } from 'src/store/app/types';
import getAttributeValue from 'src/store/design/selector/getAttributeValue';
import { DesignData } from 'src/store/design/types';
import getFormatSelectorValue from 'src/store/editor/selector/getFormatSelectorValue';
import { IntegrationLayer } from 'src/types.d';
import isFeatureEnabled from '../feature/isFeatureEnabled';
import adjustMugPrintAndBlankArea from './adjustMugPrintAndBlankArea';

const MUG_PRINT_AREA_ATTRIBUTE_NAME = 'OpMugPrintArea';

const adjustEmptyDesignStructure = (integrationLayer: IntegrationLayer, store: Store, design: DesignData) => {
  if (!isFeatureEnabled(Feature.limitMugPrintArea)) {
    return design;
  }

  const isMugProduct = mugProductTypeIds.indexOf(integrationLayer.productTypeId) !== -1;

  if (!isMugProduct) {
    return design;
  }

  // value from selector has higher priority - use it first
  const mugPrintArea = getFormatSelectorValue(store, MUG_PRINT_AREA_ATTRIBUTE_NAME) || getAttributeValue(store, MUG_PRINT_AREA_ATTRIBUTE_NAME);

  // adjust print area for mugs
  if (mugPrintArea) {
    return adjustMugPrintAndBlankArea(design, mugPrintArea);
  }

  return design;
};

export default adjustEmptyDesignStructure;
