import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';

const sendEditorApplyLayoutPostMessageOperiation = (layoutIndex: number, callback?: any) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  sendPostMessage({
    type: MESSAGE_TYPE['editor.applyLayout'],
    data: layoutIndex,
    callback,
  });
};

export default sendEditorApplyLayoutPostMessageOperiation;
