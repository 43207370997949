import { PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from 'src/store/editor/types';

interface SetProductDimensionsPayload {
  width: number;
  height: number;
}

const setProductDimensionsReducer = (state: EditorState, action: PayloadAction<SetProductDimensionsPayload>) => {
  state.productDimensions = action.payload;
};

export default setProductDimensionsReducer;
