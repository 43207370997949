import { getCurrentProduct } from '../formats/generateFormatTabData';
import useIntegrationLayer from '../hook/useIntegrationLayer';

export default function resolveBundleProductUid(productId: number, bundleProductId: number) {
  const integrationLayer = useIntegrationLayer();
  const currentProduct = getCurrentProduct(integrationLayer, productId);

  const foundItem = currentProduct?.bundleGroups
    .flatMap((bg) => bg.items)
    .find((item) => item?.productId === bundleProductId);

  return foundItem ? foundItem.productUid : null;
}
