import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import getBundledProductsPreviewParameters from 'src/util/bundles/getBundledProductsPreviewParameters';
import globalVars from 'src/util/globalVars/globalVars';
import gelatoApiDesignRendersLoader from 'src/util/preview/gelatoApiDesignRendersLoader';
import decorateUrlWithExtraParameters from 'src/util/url/decorateUrlWithExtraParameters';

const sendEditorRenderedPreviewsPostMessageOperation = (designData: any) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  try {
    const renders = await gelatoApiDesignRendersLoader.loadDesignRenders(designData);
    if (renders) {
      const extraParameters = getBundledProductsPreviewParameters(window.integrationLayer, globalVars.productId || 0, globalVars.bundledProducts);
      if (extraParameters) {
        renders.flat?.forEach((preview) => {
          preview.thumbnailUrl = decorateUrlWithExtraParameters(preview.thumbnailUrl, extraParameters);
          preview.url = decorateUrlWithExtraParameters(preview.url, extraParameters);
        });
      }
      sendPostMessage({
        type: MESSAGE_TYPE['preview.setRenders'],
        data: renders,
      });
    } else {
      throw new Error('Empty result');
    }
  } catch (e) {
    sendPostMessage({
      type: MESSAGE_TYPE['preview.rendersError'],
      data: undefined,
    });
  }
};

export default sendEditorRenderedPreviewsPostMessageOperation;
