import React from 'react';
import { useSelector } from 'react-redux';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store';
import getPrice from 'src/store/price/selector/getPrice';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import Price from 'src/component/Price';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import styles from './index.module.scss';

interface Props {
  sticker: AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt;
}

const StickerWithPrice = ({ sticker }: Props) => {
  const categoryId = useSelector(getCategoryId);
  const stickerPrice = useSelector((store: Store) => getPrice(store, categoryId || 1, sticker.productId, sticker.quantity));
  const singleStickerPrice = useSelector((store: Store) => getPrice(store, categoryId || 1, sticker.productId, 1));
  const priceFormat = useSelector(getPriceFormat);
  const currencyId = useSelector(getCurrencyId);

  return (
    <div className={styles.sticker} key={sticker.clipArtId}>
      <div className={styles.imageContainer}>
        <img src={sticker.imageUrl} alt={sticker.title} />
        {sticker.quantity > 1 && <span>{sticker.quantity}</span>}
      </div>
      {stickerPrice && currencyId && priceFormat && (
        <div className={styles.priceContainer}>
          <Price className={styles.price} price={productPriceToPrice(stickerPrice)} currencyId={currencyId} priceFormat={priceFormat} />
          {singleStickerPrice && sticker.quantity > 1 && (
            <div className={styles.breakdownContainer}>
              <Price className={styles.breakdown} price={productPriceToPrice(singleStickerPrice)} currencyId={currencyId} priceFormat={priceFormat} />
              <span className={styles.breakdown}>x</span>
              <span className={styles.breakdown}>{`${sticker.quantity}`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StickerWithPrice;
