import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getDesignId from 'src/store/design/selector/getDesignId';
import isPublicDesign from 'src/store/design/selector/isPublicDesign';
import sendUploaderUploadSuccessPostMessageOperation
  from 'src/store/gallery/operation/sendUploaderUploadSuccessPostMessageOperation';
import { UploadedImageData } from 'src/store/gallery/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { addGalleryImagesAction } from '../slice';
import sendUploaderUploadFailedPostMessageOperation from './sendUploaderUploadFailedPostMessageOperation';

const importImageFromTemporaryStorageToOpOperation = (data: UploadedImageData) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const { name } = data.file;
  const { url } = data;

  const store = getState();
  const customerDesignId = getDesignId(store);
  const isPublic = isPublicDesign(store);

  if (isPublic) {
    return;
  }

  const request: any = {
    filename: name,
    sourcePictureId: data.file.id,
    sourcePictureUrl: url,
    url,
  };

  if (customerDesignId) {
    request.customerDesignId = customerDesignId;
  }
  try {
    const response = (await opApiRequest(
      endpoints.GALLERY_V1_IMPORT,
      request,
      'data',
      'POST',
    )) as apiTypes.AppBundle.Api.Entity.Photo.V1.IPhoto;

    if (response && response.url) {
      data.url = response.url;
      dispatch(addGalleryImagesAction([response]));
      dispatch(sendUploaderUploadSuccessPostMessageOperation(data.file.id, response.url, response.ow, response.oh));
    } else {
      // eslint-disable-next-line no-console
      console.error('Could not import image, response: ', response);
      dispatch(sendUploaderUploadFailedPostMessageOperation(data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not import image, exception: ', e);
    dispatch(sendUploaderUploadFailedPostMessageOperation(data));
  }
};

export default importImageFromTemporaryStorageToOpOperation;
