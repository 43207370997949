import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setTemplateCanAddPagesAction, setTemplateForcedMinPageCountAction } from '../slice';

const setTemplateInitialStateFromIntegrationLayerOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const {
    canAddPages,
    forcedMinPageCount,
  } = useIntegrationLayer();
  dispatch(setTemplateCanAddPagesAction(canAddPages));
  dispatch(setTemplateForcedMinPageCountAction(forcedMinPageCount));
};

export default setTemplateInitialStateFromIntegrationLayerOperation;
