import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setDesignStructureAction } from 'src/store/design/slice';
import { cloneDeep } from 'lodash';
import getStructure from '../selector/getStructure';

const updateProductUidInDesignStructureOperation = (productUid: string) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const designData = cloneDeep(getStructure(getState()));
  if (!designData) return;

  designData.product_uid = productUid;
  dispatch(setDesignStructureAction(designData));
};

export default updateProductUidInDesignStructureOperation;
