import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';

interface AddonCounter {
  clipArtId: number;
  quantity: number;
}

const fetchClipArts = async (clipArtIds: number[]) => {
  try {
    const clipArtsInUse = (await opApiRequest(
      endpoints.CLIP_ART_V1_GET,
      {
        clipArtIds,
      },
      'data.clipArts',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt[];
    return clipArtsInUse;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch clip arts by ids, exception: ', e);
    return undefined;
  }
};

const fetchClipArtsOperation = (clipArtIds: AddonCounter[]) => async (dispatch: ThunkDispatch<Store, undefined, AnyAction>) => {
  const clipArtsInUse = await fetchClipArts(clipArtIds.map((ca) => ca.clipArtId));
  if (clipArtsInUse) {
    clipArtsInUse.forEach((ca) => {
      ca.quantity = clipArtIds.find((caq) => ca.clipArtId === caq.clipArtId)?.quantity || 1;
    });
  }

  return clipArtsInUse;
};

export default fetchClipArtsOperation;
