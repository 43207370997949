import React, { useRef, useEffect, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import addToBasketOperation from 'src/store/app/operation/addToBasketOperation';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getInternalUid from 'src/store/design/selector/getInternalUid';
import getProductId from 'src/store/design/selector/getProductId';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';
import upgradeProductByUpsellOperation from 'src/store/upsell/operation/upgradeProductByUpsellOperation';
import getDisplayAddToBasketUpsell from 'src/store/upsell/selector/getDisplayAddToBasketUpsell';
import { displayUpsellBeforeAddToBasketAction, setBeforeAddToBasketUpsellWasShownAction } from 'src/store/upsell/slice';
import resolveBundleProductUid from 'src/util/bundles/resolveBundleProductUid';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';

interface UpgradeProductCustomEvent extends CustomEvent {
  details: {
    abstractProductKey: string;
    abstractProductId: number;
    productId: number;
    productUid: string;
    internalUid: string;
    bundledProducts: string;
  };
}

const BeforeAddToBasketPopup = () => {
  const dispatch = useDispatch();
  const internalUid = useSelector(getInternalUid);
  const productId = useSelector(getProductId);
  const slug = useSelector(getAbstractProduct)?.slug;
  const categoryId = useSelector(getCategoryId);
  const bundleProductId = useSelector(getProductBundleSelectedProducts)[0]?.productId;
  const bundleProductUid = productId && bundleProductId > 0 ? resolveBundleProductUid(productId, bundleProductId) : undefined;

  const shouldTryToDisplay = useSelector(getDisplayAddToBasketUpsell);
  const aovPopupRef = useRef();

  const sendGAEvent = useCallback((event: any) => gtmSendEvent(event), []);

  const addToBasket = () => {
    batch(() => {
      dispatch(setBeforeAddToBasketUpsellWasShownAction(true));
      dispatch(displayUpsellBeforeAddToBasketAction(false));
    });
    dispatch(addToBasketOperation());
  };

  const upgradeProduct = useCallback(async (event: UpgradeProductCustomEvent) => {
    const {
      productId, productUid, internalUid = productUid, bundledProducts = [],
    } = event.detail;
    await dispatch(upgradeProductByUpsellOperation(productUid, internalUid, productId, bundledProducts));
    addToBasket();
  }, []);

  const popupClosed = useCallback(() => {
    dispatch(displayUpsellBeforeAddToBasketAction(false));
  }, []);

  const skipProductUpgrade = useCallback(() => {
    addToBasket();
  }, []);

  const eventListeners = {
    ceAOVCancel: popupClosed,
    ceSendAnalytics: sendGAEvent,
    ceContinueWithCurrentProduct: skipProductUpgrade,
    ceUpgradeProduct: upgradeProduct,
  } as { [key: string]: any };

  const updateEventListeners = (eventUpdater: (event: string, handler: (event: Event) => void) => void) => {
    Object.keys(eventListeners).forEach((eventName) => {
      eventUpdater(eventName, eventListeners[eventName]);
    });
  };

  useEffect(() => {
    if (shouldTryToDisplay) {
      updateEventListeners(window.addEventListener);
      return () => {
        updateEventListeners(window.removeEventListener);
      };
    }
    return () => {};
  }, [shouldTryToDisplay]);

  if (!shouldTryToDisplay) return null;

  return (
    <aov-popup
      ref={aovPopupRef}
      internal-uid={internalUid}
      abstract-product={slug}
      category-id={categoryId}
      bundle-product-uid={bundleProductUid}
      analytics-event="ceSendAnalytics"
      continue-without-suggestion-event="ceContinueWithCurrentProduct"
      close-event="ceAOVCancel"
      confirm-suggestion-event="ceUpgradeProduct"
    />
  );
};

export default BeforeAddToBasketPopup;
