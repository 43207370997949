import { PayloadAction } from '@reduxjs/toolkit';
import { UpsellState } from 'src/store/upsell/types';

const displayUpsellBeforeAddToBasketReducer = (
  state: UpsellState, action: PayloadAction<boolean>,
) => {
  state.displayUpsellBeforeAddToBasket = action.payload;
};

export default displayUpsellBeforeAddToBasketReducer;
