import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import { DesignData } from 'src/store/design/types';
import { setProductDimensionsAction } from 'src/store/editor/slice';

const setDefaultProductDimensionsOperation = (design: DesignData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  if (!design || !design.dimensions) {
    return;
  }
  const { width, height } = design.dimensions;
  dispatch(setProductDimensionsAction({ width, height }));
};

export default setDefaultProductDimensionsOperation;
