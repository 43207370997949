import { AnyAction } from 'redux';
import sendProductUidChangedPostMessageOperation
  from 'src/store/design/operation/sendProductUidChangedPostMessageOperation';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getProductUid from 'src/store/design/selector/getProductUid';
import getSelectedProductAttributes from 'src/store/design/selector/getSelectedProductAttributes';
import { Product, Selector } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { cloneDeep } from 'lodash';
import {
  setDesignSelectedProductAttributesAction,
} from '../slice';

const changeProductAttributeOperation = (selectorTypeName: string, value: string) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  const abstractProduct = getAbstractProduct(store);
  if (!abstractProduct) {
    return;
  }

  const currentAttributes = cloneDeep(getSelectedProductAttributes(store));

  let products = cloneDeep(abstractProduct.products);

  // We iterate first over list of attributes to maintain their order
  abstractProduct.editorSelectors.forEach((editorSelector, index) => {
    let selectedAttribute = cloneDeep(currentAttributes[editorSelector.typeName]);
    if (selectedAttribute && selectorTypeName === selectedAttribute.typeName) {
      selectedAttribute.value = value;
    } else {
      selectedAttribute = {
        value,
        typeName: editorSelector.typeName,
      };
    }

    if (isAttributeAvailableInProductsList(products, selectedAttribute.typeName, selectedAttribute.value)) {
      products = filterProductsByAttribute(products, selectedAttribute.typeName, selectedAttribute.value);
      currentAttributes[selectedAttribute.typeName] = selectedAttribute;
    } else {
      const firstValue = findFirstAvailableValueForAttributeType(abstractProduct.editorSelectors, products, selectedAttribute.typeName);
      if (firstValue) {
        products = filterProductsByAttribute(products, selectedAttribute.typeName, firstValue);
        currentAttributes[selectedAttribute.typeName] = {
          typeName: selectedAttribute.typeName,
          value: firstValue,
        };
      }
    }
  });

  dispatch(setDesignSelectedProductAttributesAction(currentAttributes));
  const attrs: string[] = Object.keys(currentAttributes);

  // eslint-disable-next-line array-callback-return
  const product = abstractProduct.products.find((p) => {
    let matches = 0;
    const productAttributes = p.attributes;
    for (let j = 0; j < productAttributes.length; j += 1) {
      const attributesList = Object.values(currentAttributes);
      for (let i = 0; i < attributesList.length; i += 1) {
        if (productAttributes[j].typeName === attributesList[i].typeName && productAttributes[j].value === attributesList[i].value) {
          matches += 1;
        }
      }
    }
    return matches === attrs.length;
  });

  if (product) {
    if (product.productUid !== getProductUid(store)) {
      await dispatch(sendProductUidChangedPostMessageOperation({
        productUid: product.productUid,
        internalUid: product.internalUid,
        productId: product.productId,
        categoryId: window.integrationLayer.categoryId,
      }));
    }
  }
};

export default changeProductAttributeOperation;

const isAttributeAvailableInProductsList = (products: Product[], typeName: string, value: string) => {
  for (let j = 0; j < products.length; j += 1) {
    const p = products[j];
    for (let i = 0; i < p.attributes.length; i += 1) {
      const a = p.attributes[i];
      if (a.typeName === typeName && a.value === value) {
        return true;
      }
    }
  }
  return false;
};

const filterProductsByAttribute = (products: Product[], typeName: string, value: string) => {
  const newProducts: Product[] = [];
  products.forEach((p) => {
    for (let i = 0; i < p.attributes.length; i += 1) {
      const a = p.attributes[i];
      if (a.typeName === typeName && a.value === value) {
        newProducts.push(p);
      }
    }
  });

  return newProducts;
};

const findFirstAvailableValueForAttributeType = (editorSelectors: Selector[], products: Product[], typeName: string) => {
  const selector = editorSelectors.find((s) => s.typeName === typeName);

  if (!selector) {
    return null;
  }

  for (let i = 0; i < selector.options.length; i += 1) {
    const option = selector.options[i];
    for (let j = 0; j < products.length; j += 1) {
      const p = products[j];
      for (let k = 0; k < p.attributes.length; k += 1) {
        const a = p.attributes[k];
        if (a.typeName === selector.typeName && option.value === a.value) {
          return option.value;
        }
      }
    }
  }
  return null;
};
