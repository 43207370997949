import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import { UploadedImageData } from '../types';

const sendUploaderUploadFailedPostMessageOperation = (data: UploadedImageData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => sendPostMessage({
  type: MESSAGE_TYPE['uploader.uploadError'],
  data,
});

export default sendUploaderUploadFailedPostMessageOperation;
