import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { UploadedImageData } from 'src/store/gallery/types';
import { Store } from 'src/store/index';
import { DesignData, MediaElement } from 'src/store/design/types';

const imagesFilterCondition = (media: MediaElement) => media.name === 'PublicAsset' || media.name.startsWith('image_optimalprint_dl');

const sendPublicAssetsToGalleryOperation = (design: DesignData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  let imagesToSend: UploadedImageData[] | undefined;

  try {
    imagesToSend = design.spreads[0].pages[0].groups.media?.filter(imagesFilterCondition).map((media) => ({
      url: media.url,
      file: {
        id: media.imageId,
        width: media.extra?.piw,
        height: media.extra?.pih,
      },
    } as UploadedImageData));
  } catch {
    return;
  }

  if (!imagesToSend || !imagesToSend.length) {
    return;
  }

  sendPostMessage({
    type: MESSAGE_TYPE['gallery.loadUploadedImages'],
    data: imagesToSend,
  });
};

export default sendPublicAssetsToGalleryOperation;
