import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getProductUid from 'src/store/design/selector/getProductUid';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import sendPostMessage from './sendPostMessage';
import { MESSAGE_TYPE } from '../types';

interface ImageRequirementsResponse {
  dpi: {
    enabled: boolean;
    productTier: string;
    min: number;
    ok: number;
    max: number;
  };
}

const fetchAndSetImageRequirementsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const productUid = getProductUid(store);

  const defaultDpiSettings: ImageRequirementsResponse = {
    dpi: {
      enabled: false,
      productTier: '',
      min: 0,
      ok: 0,
      max: 0,
    },
  };

  let response = defaultDpiSettings;
  try {
    response = (await opApiRequest(
      endpoints.PRODUCT_V1_IMAGE_REQUIREMENTS,
      {
        productUid,
      },
      'data',
      'GET',
    )) as ImageRequirementsResponse;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch image requirements', e);
  }

  if (!response) return;

  // dispatch(setIsLowDPIWarningEnabledAction(response.dpi.enabled));

  gtmSendEvent({
    event: 'lowDpiExperimentInvolved',
    lowDpiExperimentProductTier: response.dpi.productTier,
    lowDpiExperimentValue: response.dpi.enabled ? 'on' : 'off',
  });

  sendPostMessage({
    type: MESSAGE_TYPE['editor.setEditorHostSettings'],
    data: {
      changeImageFrameColorBasedOnDPI: response.dpi.enabled,
      dpiLevels: true,
      hiddenWarnings: {
        LowDPI: !response.dpi.enabled,
      },
    },
  });
};

export default fetchAndSetImageRequirementsOperation;
