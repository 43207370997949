import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Store } from 'src/store';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getMinPagesCount from 'src/store/design/selector/getMinPagesCount';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getTotalPagesCount from 'src/store/design/selector/getTotalPagesCount';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getCurrentDesignPrice from 'src/store/price/selector/getCurrentDesignPrice';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import calculateDesignPrice from 'src/util/price/calculateDesignPrice';
import getUsedClipArtsPrice from 'src/store/addon/selector/getUsedClipArtsPrice';
import { Price as RawPrice } from 'src/store/price/types';
import addExtraPrice from 'src/util/price/addExtraPrice';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { DropDownMenuEnum, Feature } from 'src/store/app/types';
import getUpsellTotalPrice from 'src/store/upsell/selector/getUpsellTotalPrice';
import getQuantity from 'src/store/design/selector/getQuantity';
import formatPrice from 'src/util/price/formatPrice';
import getOpenedDropdownMenu from 'src/store/app/selector/getOpenedDropdownMenu';
import cn from 'classnames';
import getUsedClipArts from 'src/store/addon/selector/getUsedClipArts';
import styles from './index.module.scss';

interface Props {
  productTypeId?: number;
  currencyId?: string;
  priceFormat?: string;
  totalPagesCount?: number;
  minPages?: number;
  addonPrice: RawPrice;
  upsellPrice: RawPrice;
  addonSupportEnabled: boolean;
  isMobile: boolean;
  price?: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice;
  quantity?: number;
}

const DesignBreakdownPrices = ({
  price,
  currencyId,
  priceFormat,
  productTypeId,
  totalPagesCount,
  minPages,
  addonPrice,
  upsellPrice,
  addonSupportEnabled,
  quantity,
  isMobile,
}: Props) => {
  const { t } = useTranslation();
  const stickers = useSelector((store: Store) => getUsedClipArts(store));

  if (!productTypeId) {
    return null;
  }

  if (!price || !currencyId || !priceFormat || !productTypeId || !totalPagesCount) {
    return null;
  }

  const basePrice = calculateDesignPrice(price, productTypeId, minPages, totalPagesCount);
  let calculatedAddonPrice: RawPrice = { price: 0, priceInitial: 0 };

  if (addonSupportEnabled) {
    calculatedAddonPrice = addExtraPrice(basePrice, addonPrice, quantity);
  }

  let upsellFinalPrice: RawPrice | undefined;
  if (upsellPrice?.price > 0) {
    upsellFinalPrice = addExtraPrice({ price: 0, priceInitial: 0 }, upsellPrice, quantity);
  }

  const translationKey = `txt_product_type_name_singular_${productTypeId}`;

  return (
    <div
      className={cn(styles.container, {
        [styles.mobileContainer]: isMobile,
      })}
    >
      {isMobile && (
      <div
        className={cn(styles.item, styles.priceDetails, {
          [styles.mobileItem]: isMobile,
        })}
      >
        {t('txt_price_details')}
      </div>
      )}
      <div
        className={cn(styles.item, {
          [styles.mobileItem]: isMobile,
        })}
      >
        <span className={styles.label}>{`${t(translationKey)}: `}</span>
        <span className={styles.price}>{formatPrice(basePrice.price, priceFormat, currencyId)}</span>
      </div>
      {upsellFinalPrice && upsellFinalPrice.price > 0 && (
        <div
          className={cn(styles.item, {
            [styles.mobileItem]: isMobile,
          })}
        >
          <span className={styles.label}>{`${t('txt_printing_costs')}: `}</span>
          <span className={styles.price}>{formatPrice(upsellFinalPrice.price, priceFormat, currencyId)}</span>
        </div>
      )}
      {addonPrice && addonPrice.price > 0 && (
        <div
          className={cn(styles.item, {
            [styles.mobileItem]: isMobile,
          })}
        >
          <span className={styles.label}>{t('txt_designs')}</span>
          {isMobile && (
          <span className={styles.label} style={{ marginLeft: isMobile ? '3px' : 0 }}>
            (
            {stickers.length}
            )
          </span>
          )}
          <span className={styles.label}>: </span>
          <span className={styles.price}>{formatPrice(calculatedAddonPrice.price - basePrice.price, priceFormat, currencyId)}</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  productTypeId: getProductTypeId(store),
  price: getCurrentDesignPrice(store),
  currencyId: getCurrencyId(store),
  priceFormat: getPriceFormat(store),
  totalPagesCount: getTotalPagesCount(store),
  minPages: getMinPagesCount(store),
  addonPrice: getUsedClipArtsPrice(store),
  upsellPrice: getUpsellTotalPrice(store),
  addonSupportEnabled: isFeatureEnabled(store, Feature.supportClipArt),
  quantity: getQuantity(store),
  isDropdownVisible: getOpenedDropdownMenu(store) === DropDownMenuEnum.stickersDropdown,
});

export default connect(mapStateToProps)(DesignBreakdownPrices);
