import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import { MESSAGE_TYPE } from 'src/store/app/types';
import addDesignErrorOperation from 'src/store/design/operation/addDesignErrorOperation';
import removeDesignErrorOperation from 'src/store/design/operation/removeDesignErrorOperation';
import { DesignError } from 'src/store/design/types';
import importImageFromTemporaryStorageToOpOperation
  from 'src/store/gallery/operation/importImageFromTemporaryStorageToOpOperation';
import { UploadedImageData } from 'src/store/gallery/types';

const processUploaderEvents = (messageType: MESSAGE_TYPE | string, data: UploadedImageData, dispatch: ThunkDispatch<Store, void, AnyAction>) => {
  let processed = true;
  switch (messageType) {
    case MESSAGE_TYPE['uploader.assetUploaded']:
      dispatch(importImageFromTemporaryStorageToOpOperation(data));
      break;
    case MESSAGE_TYPE['uploader.uploadStarted']:
      dispatch(addDesignErrorOperation(DesignError.uploadInProgress));
      break;
    case MESSAGE_TYPE['uploader.uploadComplete']:
      dispatch(removeDesignErrorOperation(DesignError.uploadInProgress));
      break;
    default:
      processed = false;
  }
  return processed;
};

export default processUploaderEvents;
