import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import isAttributeSelected from 'src/store/design/selector/isAttributeSelected';
import sendLayoutSchemasPostMessage from 'src/store/app/operation/sendLayoutSchemasPostMessage';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import getAttributeValue from 'src/store/design/selector/getAttributeValue';
import { Feature } from 'src/store/app/types';
import getCurrentLayoutIndex from 'src/util/design/getCurrentLayoutIndex';
import getStructure from 'src/store/design/selector/getStructure';
import getLayoutSchemaSetName from '../selector/getLayoutSchemaSetName';
import sendEditorApplyLayoutPostMessageOperiation from './sendEditorApplyLayoutPostMessageOperiation';

const updateMugLayoutSchemasOperation = (applyLayout = false) => (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getStore: () => Store,
) => {
  const store = getStore();
  const isWrapAroundMug = isAttributeSelected(store, 'OpMugPrintArea', 'wrap-around');
  const isSimplePhotoMug = isAttributeSelected(store, 'OpMugPrintArea', 'simple-photo');
  const printAreaValue = getAttributeValue(store, 'OpMugPrintArea');
  const currentSchemaSet = getLayoutSchemaSetName(store);

  let newSchemaSetName;

  if (isWrapAroundMug) {
    newSchemaSetName = 'mug-wrap-around';
  }
  if (isSimplePhotoMug) {
    newSchemaSetName = 'mug-simple-photo';
  }
  if (isFeatureEnabled(Feature.limitMugPrintArea)) {
    switch (printAreaValue) {
      case 'simple-photo-left':
        newSchemaSetName = 'mug-simple-photo-left';
        break;
      case 'simple-photo':
        newSchemaSetName = 'mug-simple-photo-right';
        break;
      case 'wrap-around':
        newSchemaSetName = 'mug-wrap-around';
        break;
      case 'default':
        newSchemaSetName = 'mug-wrap-around';
        break;
      default:
    }
  }

  if (!newSchemaSetName || newSchemaSetName === currentSchemaSet) {
    return;
  }
  dispatch(sendLayoutSchemasPostMessage(newSchemaSetName, isFeatureEnabled(Feature.addEmptyLayout)));
  if (applyLayout) {
    const currentLayoutIndex = getCurrentLayoutIndex(newSchemaSetName, getStructure(store), Number(isFeatureEnabled(Feature.addEmptyLayout)), 0);
    dispatch(sendEditorApplyLayoutPostMessageOperiation(currentLayoutIndex));
  }
};

export default updateMugLayoutSchemasOperation;
