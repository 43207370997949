const dataLayerPush = (event: string, props: any) => {
  if (!event) return;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event,
    ...props,
  });
};

export default dataLayerPush;
