import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import getBasketUrl from 'src/store/app/selector/getBasketUrl';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import getOrderItemId from 'src/store/design/selector/getOrderItemId';
import getOriginalQuantity from 'src/store/design/selector/getOriginalQuantity';
import getQuantity from 'src/store/design/selector/getQuantity';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { setDesignIsSavingAction, setDesignIsLoadingAction } from 'src/store/design/slice';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import getStructure from 'src/store/design/selector/getStructure';
import isDesignHasEmptyContent from 'src/util/design/isDesignHasEmptyContent';
import trackEventOperation from 'src/store/addon/operation/trackEventOperation';
import shouldDisplayLowDPIWarning from 'src/store/design/selector/shouldDisplayLowDPIWarning';
import updateBundledProductsOperation from './updateBundledProductsOperation';
import { Feature } from '../types';
import sendEmptyProductWarningOperation from './sendEmptyProductWarningOperation';
import showPopupOperation from './showPopupOperation';

const updateOrderItemOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const designData = getStructure(store);

  if (isDesignSaving(store)) {
    return;
  }

  if (isDesignHasEmptyContent(designData)) {
    trackEventOperation('customerAttemptUpdateCartWithEmptyDesign');
  }

  if (isFeatureEnabled(Feature.DisplayWarningPopupWhenEmptyDesign) && designData && isDesignHasEmptyContent(designData)) {
    dispatch(sendEmptyProductWarningOperation());
    return;
  }

  if (shouldDisplayLowDPIWarning(store)) {
    dispatch(showPopupOperation('LowDPIWarningPopup'));
    return;
  }

  try {
    dispatch(setDesignIsSavingAction(true));
    dispatch(setDesignIsLoadingAction(true));
    const designUpdateResult = await dispatch(updateCustomerDesignOperation());
    if (!designUpdateResult) {
      dispatch(showPopupOperation('UpdateDesignWarningPopup'));
      dispatch(trackEventOperation('customerDesignUpdateFailed'));
      throw new Error('Error updating customer design');
    }
    await dispatch(updateBundledProductsOperation());
    if (getOrderItemId(store) && getQuantity(store) !== getOriginalQuantity(store)) {
      try {
        await opApiRequest(
          endpoints.CART_V1_ITEM_QUANTITY_UPDATE,
          {
            quantity: getQuantity(store),
            orderItemId: getOrderItemId(store),
          },
          'data',
          'POST',
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Cannot update customer order item quantity', e);
        throw e;
      }
    }
    dispatch(goToOptimalprintOperation(getBasketUrl(store)));
  } catch (e) {
    dispatch(setDesignIsSavingAction(false));
    dispatch(setDesignIsLoadingAction(false));
    // eslint-disable-next-line no-console
    console.error('Cannot update customer order item', e);
  }
};

export default updateOrderItemOperation;
