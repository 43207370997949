import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { studioSelectionProductTypeIds } from 'src/api/productTypes';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { Feature, MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const sendEditorFeaturesPostMessageOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  sendPostMessage({
    type: MESSAGE_TYPE['editor.setEditorHostSettings'],
    data: {
      allowAddElements: isFeatureEnabled(store, Feature.allowAddElements),
      allowRemoveElements: isFeatureEnabled(store, Feature.allowRemoveElements),
      allowLockElements: isFeatureEnabled(store, Feature.allowElementLockControl),
      allowPersonalizationLock: false,
    },
  });

  const integrationLayer = useIntegrationLayer();
  const isStudioSelectionProduct = studioSelectionProductTypeIds.filter((id) => id !== 176).includes(integrationLayer.productTypeId);
  if (isStudioSelectionProduct) {
    sendPostMessage({
      type: MESSAGE_TYPE['plugins.setPlugins'],
      data: [
        {
          name: 'app-image-filters',
          state: 'enabled',
          extra: {
            noLogo: true,
          },
        }],
    });
  }
};

export default sendEditorFeaturesPostMessageOperation;
