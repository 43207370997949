import React from 'react';

const DangerIcon = () => (
  <svg width="45" height="38" viewBox="0 0 45 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M43.6384 33.9328L24.8387 1.34791C23.7995 -0.449304 21.2015 -0.449304 20.1623 1.34791L1.36264 33.9328C0.323462 35.73 1.59888 38 3.67726 38H41.2766C43.4018 38 44.6772 35.7297 43.638 33.9328H43.6384ZM21.9101 11.3267C23.0438 11.043 24.1773 11.6104 24.6969 12.6509C24.8857 13.0293 24.9331 13.4549 24.8857 13.8333C24.7911 15.0156 24.744 16.1979 24.6969 17.4274C24.6023 19.2719 24.4607 21.1634 24.3661 23.0078C24.319 23.5753 24.319 24.143 24.2715 24.7576C24.2244 25.7509 23.4686 26.5074 22.4765 26.5074C21.5319 26.5074 20.7289 25.7506 20.6815 24.8048C20.5398 21.9199 20.3507 19.0351 20.209 16.1502C20.1619 15.3934 20.1144 14.5895 20.0673 13.8328C20.068 12.6982 20.8238 11.6107 21.9102 11.3269L21.9101 11.3267ZM22.5243 32.9869C21.2017 32.9869 20.1625 31.8993 20.1625 30.5751C20.1625 29.2509 21.2488 28.1632 22.5714 28.1632C23.894 28.1632 24.9332 29.2509 24.9332 30.6225C24.8861 31.8988 23.7997 32.9868 22.5243 32.9868V32.9869Z"
      fill="#333333"
    />
  </svg>
);

export default DangerIcon;
