import { createSlice } from '@reduxjs/toolkit';
import setEditorScenesReducer from 'src/store/editor/reducer/setEditorScenesReducer';
import resetFormatSelectorValuesReducer from 'src/store/editor/reducer/resetFormatSelectorValuesReducer';
import setFormatSelectorValueReducer from 'src/store/editor/reducer/setFormatSelectorValueReducer';
import setLayoutSchemaSetNameReducer from 'src/store/editor/reducer/setLayoutSchemaSetNameReducer';
import setEditorBackgroundColorReducer from 'src/store/editor/reducer/setEditorBackgroundColorReducer';
import { EditorState } from 'src/store/editor/types';
import { DEFAULT_EDITOR_BACKGROUND } from 'src/settings/settings';
import setProductDimensionsReducer from './reducer/setProductDimensionsReducer';

export const editorInitialState: EditorState = {
  editorScenes: {},
  layoutSchemaSetName: '',
  formatSelectors: {},
  backgroundColor: DEFAULT_EDITOR_BACKGROUND,
};

export const slice = createSlice({
  name: 'editor',
  initialState: editorInitialState,
  reducers: {
    setEditorScenesAction: setEditorScenesReducer,
    setFormatSelectorValueAction: setFormatSelectorValueReducer,
    resetFormatSelectorValuesAction: resetFormatSelectorValuesReducer,
    setLayoutSchemaSetNameAction: setLayoutSchemaSetNameReducer,
    setEditorBackgroundColorAction: setEditorBackgroundColorReducer,
    setProductDimensionsAction: setProductDimensionsReducer,
  },
});

export const {
  setEditorScenesAction,
  setFormatSelectorValueAction,
  resetFormatSelectorValuesAction,
  setLayoutSchemaSetNameAction,
  setEditorBackgroundColorAction,
  setProductDimensionsAction,
} = slice.actions;

const editor = slice.reducer;

export default editor;
