import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getEncryptedDesignId from 'src/store/design/selector/getEncryptedDesignId';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { SetPricesActionPayload } from 'src/store/price/reducer/setPricesReducer';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import { setPriceFormatAction, setCurrencyIdAction, setPricesAction } from 'src/store/price/slice';

const fetchPricesOperation = (categoryId: number, productIds: number[], sendExtraParams = false) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const extraParams = sendExtraParams ? { encryptedDesignId: getEncryptedDesignId(store) } : undefined;
  const response = (await opApiRequest(
    endpoints.PRODUCT_V1_PRICE_LIST,
    {
      categoryId,
      productIds,
      ...extraParams,
    },
    'data',
    'POST',
  )) as apiTypes.AppBundle.Api.Response.Product.V1.ProductPriceListV1Response;

  if (!response) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch prices');
    return;
  }
  if (!getPriceFormat(store)) {
    dispatch(setPriceFormatAction(response.priceFormat));
  }

  if (!getCurrencyId(store)) {
    dispatch(setCurrencyIdAction(response.currencyId));
  }

  const payload: SetPricesActionPayload = {
    categoryId,
    prices: response.products,
  };

  dispatch(setPricesAction(payload));
};

export default fetchPricesOperation;
