import { Selector } from 'src/store/design/types';

const mapSlectorsForApiEditor = (selectors: Selector[]) => {
  selectors.forEach((selector, index) => {
    if (selector.typeName === 'WallpaperSize') {
      selectors[index] = {
        isHidden: false,
        type: 'product-size',
        typeName: 'wallSize',
        name: selector.name,
        description: selector.description,
        detailsTitle: selector.detailsTitle,
        details: selector.details,
        options: [],
        skipPriceShowing: true,
      };
    } else {
      selector.type = 'complex';
      let optionsHaveNames = false;
      let optionsHaveColors = false;
      selector.options.forEach((option) => {
        if (option.hexColor) {
          optionsHaveColors = true;
        }
        if (option.name) {
          optionsHaveNames = true;
        }
        if (option.hexColor2) {
          option.hexColor = `${option.hexColor};${option.hexColor2}`;
        }
      });
      if (!optionsHaveNames && optionsHaveColors) {
        selector.type = 'color';
      }
    }
  });
  return selectors;
};

export default mapSlectorsForApiEditor;
