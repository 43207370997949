import { DesignData } from 'src/store/design/types';

export interface DesignStat {
  totalSpreads: number;
  totalPages: number;
  totalPagesWithContent: number;
  totalCustomerElements: number;
  emptyPlaceHolders: number;
  sampleElements: number;
  pagesWithContent: {
    [spreadName: string]: number;
  };
}

const getDesignStat = (designData?: DesignData) => {
  const stat: DesignStat = {
    totalSpreads: 0,
    totalPages: 0,
    totalPagesWithContent: 0,
    totalCustomerElements: 0,
    emptyPlaceHolders: 0,
    sampleElements: 0,
    pagesWithContent: {},
  };
  if (!designData) {
    return stat;
  }

  stat.totalSpreads = designData.spreads.length;
  designData.spreads.forEach((spread) => {
    spread.pages.forEach((page) => {
      stat.totalPages += 1;
      if (page.groups.media) {
        const customerElementsOnPage = page.groups.media.filter((el) => !el.sample && ((el.type === 'image' && el.imageId) || (el.type !== 'image')));
        const emptyPlaceHoldersOnPage = page.groups.media.filter((el) => el.type === 'image' && !el.imageId).length;
        const sampleElemntsOnPage = page.groups.media.filter((el) => el.sample).length;
        stat.totalCustomerElements += customerElementsOnPage.length;
        stat.emptyPlaceHolders += emptyPlaceHoldersOnPage;
        stat.sampleElements += sampleElemntsOnPage;

        if (customerElementsOnPage.length) {
          stat.totalPagesWithContent += 1;
          if (!stat.pagesWithContent[spread.name]) stat.pagesWithContent[spread.name] = 0;
          stat.pagesWithContent[spread.name] += 1;
        }
      }
    });
  });
  return stat;
};

export default getDesignStat;
