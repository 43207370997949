export default {
  PUBLIC_DESIGN_V3_GET: '/api/design/v3/get',
  PUBLIC_DESIGN_V3_GET_BY_PRODUCT_UID: '/api/design/v3/get-by-product-uid',
  PUBLIC_DESIGN_V3_GET_EDITOR_SCENES_LIST: '/api/design/v3/editor-scenes/list',
  CUSTOMER_DESIGN_V3_GET: '/api/customer/design/v3/get',
  CUSTOMER_DESIGN_V3_ADD: '/api/customer/design/v3/add',
  CUSTOMER_DESIGN_V3_PREVIEW_FOR_STRUCTURE: '/api/customer/design/v3/preview-for-structure',
  CUSTOMER_DESIGN_V3_UPDATE: '/api/customer/design/v3/update',
  CUSTOMER_DESIGN_V3_GET_CLIP_ARTS: '/api/customer/design/v3/get-clip-arts',
  PRODUCT_V1_PRICE_LIST: '/api/product/v1/price/list',
  PRODUCT_V1_UPSELL_LIST: '/api/product/v1/upsell/list',
  AUTH_V1_REGISTER_GUEST: '/api/auth/v1/register-guest',
  CART_V1_DESIGN_ADD: '/api/customer/cart/v1/design/add',
  CART_V1_ITEM_QUANTITY_UPDATE: '/api/customer/cart/v1/item/quantity/update',
  CART_V1_REMOVE_ALL_BUNDLED_ITEMS: '/api/customer/cart/v1/item/remove-all-bundled-items',
  GALLERY_V1_IMPORT: '/api/customer/gallery/v1/import',
  CUSTOMER_DESIGN_V1_PHOTO_LIST: '/api/customer/design/v1/photo/list',
  PAPER_FORMAT_V1_PRODUCT_DETAILS_LIST: '/api/paper-format/v1/product-details/list',
  PAPER_FORMAT_V1_LIST: '/api/paper-format/v1/list',
  CLIP_ART_V1_LIST: '/api/clip-art/v1/list',
  CLIP_ART_V1_GET: '/api/clip-art/v1/get',
  CART_V1_PRODUCT_ADD: '/api/customer/cart/v1/product/add',
  CUSTOMER_UPSELL_V1_EDITOR_SCREEN: '/api/customer/upsell/v1/editor-screen',
  CUSTOMER_CART_V1_VOUCHER_ADD: '/api/customer/cart/v1/voucher/add',
  PRODUCT_v1_CONTENT_GET: '/api/product/v1/content/get',
  PRODUCT_V1_IMAGE_REQUIREMENTS: '/api/product/image-requirements/v1/resolution',
};
