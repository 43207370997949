import { MESSAGE_TYPE } from 'src/store/app/types';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';

const processLogEvents = (messageType: MESSAGE_TYPE | string, data: any) => {
  let processed = false;

  if (messageType && messageType.startsWith('log.')) {
    gtmSendEvent({
      event: 'editor_interaction',
      actionType: messageType.replace('log.', ''),
      selection: data ? data.tab || data.name : undefined,
    });
    processed = true;
  }
  return processed;
};

export default processLogEvents;
