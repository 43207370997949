import { AnyAction } from 'redux';
import fetchAndSendSetSpreadBackgroundsOperation
  from 'src/store/editor/operation/fetchAndSendSetSpreadBackgroundsOperation';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import {
  setDesignInternalUidAction,
  setDesignIsLoadingAction,
  setDesignProductIdAction,
  setDesignProductUidAction,
} from '../slice';

const productUidChangedOperation = (input: { productUid: string; internalUid: string; productId: number }) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(setDesignProductUidAction(input.productUid));
  dispatch(setDesignInternalUidAction(input.internalUid));
  dispatch(setDesignProductIdAction(input.productId));
  await dispatch(fetchAndSendSetSpreadBackgroundsOperation(input.productUid));
  dispatch(setDesignIsLoadingAction(false));
};

export default productUidChangedOperation;
