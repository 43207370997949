import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import { useHistory } from 'react-router-dom';
import addToBasketOperation from 'src/store/app/operation/addToBasketOperation';
import handleEditorAnyClickOperation from 'src/store/app/operation/handleEditorAnyClickOperation';
import saveToDraftsOperation from 'src/store/app/operation/saveToDraftsOperation';
import sendSetAutoApplyLayoutSchemaNamePostMessageOperation
  from 'src/store/app/operation/sendSetAutoApplyLayoutSchemaNamePostMessageOperation';
import sendSetEditorTabsPostMessage from 'src/store/app/operation/sendSetEditorTabsPostMessage';
import sendSetEditorTranslationsPostMessageOperation
  from 'src/store/app/operation/sendSetEditorTranslationsPostMessageOperation';
import sendSetTemplateCanAddPagesPostMessageOperation
  from 'src/store/app/operation/sendSetTemplateCanAddPagesPostMessageOperation';
import sendSetTemplateForcedMinPageCountPostMessageOperation
  from 'src/store/app/operation/sendSetTemplateForcedMinPageCountPostMessageOperation';
import setAppInitialStateFromIntegrationLayerOperation
  from 'src/store/app/operation/setAppInitialStateFromIntegrationLayerOperation';
import updateOrderItemOperation from 'src/store/app/operation/updateOrderItemOperation';
import { Feature, Message, MESSAGE_TYPE } from 'src/store/app/types';
import fetchClipArtCategoryOperation from 'src/store/addon/operation/fetchClipArtCategoryOperation';
import designFormatChangedOperation from 'src/store/design/operation/designFormatChangedOperation';
import fetchDesignStructureOperation from 'src/store/design/operation/fetchDesignStructureOperation';
import setDesignInitialStateFromIntegrationLayerOperation
  from 'src/store/design/operation/setDesignInitialStateFromIntegrationLayerOperation';
import setDesignStructureOperation from 'src/store/design/operation/setDesignStructureOperation';
import setDesignTotalPagesCountOperation from 'src/store/design/operation/setDesignTotalPagesCountOperation';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import { setDesignHasStructureChangedAction, setDesignIsLoadingAction } from 'src/store/design/slice';
import fetchAndSendSetSpreadBackgroundsOperation
  from 'src/store/editor/operation/fetchAndSendSetSpreadBackgroundsOperation';
import fetchAllPaperFormatsOperation from 'src/store/format/operation/fetchAllPaperFormatsOperation';
import fetchAvailableFormatsOperation from 'src/store/format/operation/fetchAvailableFormatsOperation';
import setTemplateInitialStateFromIntegrationLayerOperation
  from 'src/store/template/operation/setTemplateInitialStateFromIntegrationLayerOperation';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import fetchAndSendClipArtInUseOperation from 'src/store/addon/operation/fetchAndSendClipArtInUseOperation';
import calculateUsedClipArtsPriceOperation from 'src/store/addon/operation/calculateUsedClipArtsPriceOperation';
import sendEnableDesignTrackChangesPostMessageOperation from 'src/store/editor/operation/sendEnableDesignTrackChangesPostMessageOperation';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import calculateUpsellPriceOperation from 'src/store/upsell/operation/calculateUpsellPriceOperation';
import sendFormatsTabDataPostMessageOperation from 'src/store/editor/operation/sendFormatsTabDataPostMessageOperation';
import sendProductUidChangedPostMessageOperation from 'src/store/design/operation/sendProductUidChangedPostMessageOperation';
import { resetFormatSelectorValuesAction, setProductDimensionsAction } from 'src/store/editor/slice';
import sendLayoutSchemasPostMessage from 'src/store/app/operation/sendLayoutSchemasPostMessage';
import sendEditorFeaturesPostMessageOperation from 'src/store/editor/operation/sendEditorFeaturesPostMessageOperation';
import setFormatSelectorValueOperation from 'src/store/editor/operation/setFormatSelectorValueOperation';
import calculateInitialExtraPriceOperation from 'src/store/editor/operation/calculateInitialExtraPriceOperation';
import sendPreventImageelementDeletionOperation from 'src/store/editor/operation/sendPreventImageelementDeletionOperation';
import sendEditorUISettingsPostMessageOperiation from 'src/store/editor/operation/sendEditorUISettingsPostMessageOperiation';
import updateMugLayoutSchemasOperation from 'src/store/editor/operation/updateMugLayoutSchemasOperation';
import sendEditorPreviewAvailabilityPostMessageOperation from 'src/store/editor/operation/sendEditorPreviewAvailabilityPostMessageOperation';
import sendEditorRenderedPreviewsPostMessageOperation from 'src/store/editor/operation/sendEditorRenderedPreviewsPostMessageOperation';
import sendEditorAddonsLogoPostMessageOperiation from 'src/store/editor/operation/sendEditorAddonsLogoPostMessageOperiation';
import updateBundledProductsOperation from 'src/store/productBundle/operation/updateBundledProductsOperation';
import changeBundledProductOperation from 'src/store/productBundle/operation/changeBundledProductOperation';
import sendEditorShowDoubleLinesPostMessageOperation from 'src/store/editor/operation/sendEditorShowDoubleLinesPostMessageOperation';
import sendEditorSettingsPostMessageOperation from 'src/store/app/operation/sendEditorSettingsPostMessageOperation';
import reflectProductSpecificSettingsOperation from 'src/store/app/operation/reflectProductSpecificSettingsOperation';
import updateProductDimensionPostMessageOperation from 'src/store/design/operation/updateProductDimensionPostMessageOperation';
import { registerCustomerAction } from 'src/store/app/slice';
import gtmSendFunnelStepEvent, { FUNNEL_STEPS } from 'src/util/gtm/gtmSendFunnelStepEvent';
import setUploaderSettingsOperation from 'src/store/app/operation/setUploaderSettingsOperation';
import fetchAndSetImageRequirementsOperation from 'src/store/app/operation/fetchAndSetImageRequirementsOperation';
import sendGtmLowDpiWarningOperation from 'src/store/design/operation/sendGtmLowDpiWarningOperation';
import processUploaderEvents from './processUploaderEvents';
import processLogEvents from './processLogEvents';

type Props = {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
};

const PostMessageReceiver = ({ dispatch }: Props) => {
  const {
    isPublicDesign, designId, layoutSchemasSet, abstractProduct, categoryName,
  } = useIntegrationLayer();
  const history = useHistory();

  const executeCommand = async (type: string, data?: any, callback?: Message) => {
    // console.log('Received message', type, data, callback);
    switch (type) {
      case MESSAGE_TYPE['editor.ready']:
        dispatch(sendEditorUISettingsPostMessageOperiation());
        dispatch(setUploaderSettingsOperation());
        dispatch(setAppInitialStateFromIntegrationLayerOperation());
        dispatch(setDesignInitialStateFromIntegrationLayerOperation());
        dispatch(setTemplateInitialStateFromIntegrationLayerOperation());
        dispatch(reflectProductSpecificSettingsOperation());
        dispatch(sendEditorSettingsPostMessageOperation());
        dispatch(sendEnableDesignTrackChangesPostMessageOperation(true));
        if (isFeatureEnabled(Feature.autoApplyLayout)) {
          dispatch(sendSetAutoApplyLayoutSchemaNamePostMessageOperation());
        }
        if (isFeatureEnabled(Feature.editorBackground)) {
          dispatch(fetchAndSendSetSpreadBackgroundsOperation());
        }
        if (isFeatureEnabled(Feature.supportClipArt)) {
          dispatch(sendEditorAddonsLogoPostMessageOperiation());
          await dispatch(fetchClipArtCategoryOperation());
          if (!isPublicDesign && designId) {
            dispatch(fetchAndSendClipArtInUseOperation(designId));
          }
        }
        if (layoutSchemasSet) {
          dispatch(sendLayoutSchemasPostMessage(layoutSchemasSet, isFeatureEnabled(Feature.addEmptyLayout)));
        }
        // try to set layoutschemas for mug products if relevant
        dispatch(updateMugLayoutSchemasOperation());
        if (isFeatureEnabled(Feature.preventImageElementDeletion)) {
          dispatch(sendPreventImageelementDeletionOperation(true));
        }
        await dispatch(fetchDesignStructureOperation());
        dispatch(sendSetEditorTabsPostMessage());
        dispatch(fetchAvailableFormatsOperation());
        dispatch(fetchAllPaperFormatsOperation());
        dispatch(sendSetEditorTranslationsPostMessageOperation());
        dispatch(sendSetTemplateCanAddPagesPostMessageOperation());
        dispatch(sendSetTemplateForcedMinPageCountPostMessageOperation());
        dispatch(sendEditorShowDoubleLinesPostMessageOperation());

        if (isFeatureEnabled(Feature.allowFormats)) {
          dispatch(sendFormatsTabDataPostMessageOperation());
        }
        dispatch(sendEditorFeaturesPostMessageOperation());
        dispatch(fetchAndSetImageRequirementsOperation());
        dispatch(calculateInitialExtraPriceOperation());
        if (isFeatureEnabled(Feature.allowPreview)) {
          dispatch(sendEditorPreviewAvailabilityPostMessageOperation());
        }
        gtmSendFunnelStepEvent(
          FUNNEL_STEPS.editor,
          `/${categoryName}/${abstractProduct?.slug || ''}`,
          { productCategory: categoryName, designSaved: true },
        );
        break;
      case MESSAGE_TYPE['success.LoadingDesignSuccess']:
        dispatch(setDesignIsLoadingAction(false));
        break;
      case MESSAGE_TYPE['design.structureChanged']:
        dispatch(sendGtmLowDpiWarningOperation(data));
        dispatch(setDesignHasStructureChangedAction(true));
        dispatch(setDesignStructureOperation(data));
        if (isFeatureEnabled(Feature.supportClipArt)) {
          dispatch(calculateUsedClipArtsPriceOperation(data));
        }
        if (isFeatureEnabled(Feature.extraChargePerPage)) {
          dispatch(calculateUpsellPriceOperation(data));
        }
        break;
      case MESSAGE_TYPE['editor.anyClick']:
        dispatch(handleEditorAnyClickOperation());
        break;
      case MESSAGE_TYPE['design.formatChanged']:
        dispatch(designFormatChangedOperation(data));
        break;
      case MESSAGE_TYPE['product.productUidChanged']:
        dispatch(setDesignHasStructureChangedAction(true));
        await dispatch(designFormatChangedOperation(data));
        await dispatch(updateBundledProductsOperation());
        if (isFeatureEnabled(Feature.allowPreview)) {
          await dispatch(sendEditorPreviewAvailabilityPostMessageOperation());
        }
        dispatch(updateMugLayoutSchemasOperation(true));
        if (isFeatureEnabled(Feature.editorBackground)) {
          dispatch(fetchAndSendSetSpreadBackgroundsOperation(data.productUid));
        }
        dispatch(fetchAndSetImageRequirementsOperation());
        if (data.width && data.height) {
          await dispatch(updateCustomerDesignOperation());
          dispatch(setProductDimensionsAction({ width: data.width, height: data.height }));
        }
        if (isFeatureEnabled(Feature.allowFormats)) {
          dispatch(resetFormatSelectorValuesAction());
          await dispatch(sendFormatsTabDataPostMessageOperation());
        }
        break;
      case MESSAGE_TYPE['design.setDesignData']:
        dispatch(setDesignStructureOperation(data));
        if (isFeatureEnabled(Feature.extraChargePerPage)) {
          dispatch(calculateUpsellPriceOperation());
        }
        break;
      case MESSAGE_TYPE['host.addToBasket']:
        await dispatch(addToBasketOperation());
        break;
      case MESSAGE_TYPE['host.updateOrderItem']:
        await dispatch(updateOrderItemOperation());
        break;
      case MESSAGE_TYPE['host.leaveEditor']:
        history.goBack();
        break;
      case MESSAGE_TYPE['host.saveToDrafts']:
        dispatch(saveToDraftsOperation());
        break;
      case MESSAGE_TYPE['design.spreadsCountChanged']:
        dispatch(setDesignTotalPagesCountOperation(data.page_count));
        break;
      case MESSAGE_TYPE['addons.requestAddons']:
        dispatch(fetchClipArtCategoryOperation(Number(data.categoryId), data.search, data.requestVersion));
        break;
      case MESSAGE_TYPE['editor.setFormatSelectorValue']:
        await dispatch(setFormatSelectorValueOperation(data));
        await dispatch(sendFormatsTabDataPostMessageOperation());
        break;
      // Message to be received after customer changed attributes in formats tab
      case MESSAGE_TYPE['editor.formatChangeRequested']:
        dispatch(sendProductUidChangedPostMessageOperation({
          ...data,
          categoryId: window.integrationLayer.categoryId,
        }));
        break;
      case MESSAGE_TYPE['preview.loadPreviews']:
        dispatch(sendEditorRenderedPreviewsPostMessageOperation(data.designData));
        break;
      case MESSAGE_TYPE['host.changeBundledProduct']:
        await dispatch(changeBundledProductOperation(data));
        await dispatch(sendFormatsTabDataPostMessageOperation());
        await dispatch(fetchAndSendSetSpreadBackgroundsOperation());
        break;
      case MESSAGE_TYPE['editor.dimensionChangeRequested']:
        dispatch(updateProductDimensionPostMessageOperation({
          width: data.width,
          height: data.height,
        }));
        break;
      default:
        break;
    }

    processUploaderEvents(type, data, dispatch);
    processLogEvents(type, data);

    if (type && type.startsWith('log.')) {
      dispatch(registerCustomerAction(type));
    }

    if (callback) {
      executeCommand(callback.type, callback.data, callback?.callback);
    }
  };

  const receiveMessage = (e: MessageEvent) => {
    executeCommand(e.data.type, e.data.data);
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);
  return (<></>);
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PostMessageReceiver);
