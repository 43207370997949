export interface AppState {
  backUrl?: string;
  forwardUrl?: string;
  draftsUrl?: string;
  basketUrl?: string;
  isMobile: boolean;
  features: Feature[];
  editorMode: EDITOR_MODE;
  openedDropdownMenu?: string;
  activePopup?: string;
  isBackdropVisible: boolean;
  appStartTime: number;
  appSessionId: number;
  customerActionsChain: string[];
}

export enum EDITOR_MODE {
  'create-new' = 'create-new',
  'edit-from-basket' = 'edit-from-basket',
  'edit-from-drafts' = 'edit-from-drafts',
}

export enum DropDownMenuEnum {
  'photobooksFormatSelector' = 'photobooksFormatSelector',
  'stickersDropdown' = 'stickersDropdown',
}

export enum Feature {
  'formatSwitcher' = 'FORMAT_SWITCHER',
  'editorBackground' = 'EDITOR_BACKGROUND',
  'supportClipArt' = 'SUPPORT_CLIPART',
  'autoApplyLayout' = 'AUTO_APPLY_LAYOUT',
  'allowSettings' = 'ALLOW_SETTINGS',
  'allowLayouts' = 'ALLOW_LAYOUTS',
  'allowTexts' = 'ALLOW_TEXTS',
  'allowLayers' = 'ALLOW_LAYERS',
  'allowFormats' = 'ALLOW_FORMATS',
  'extraChargePerPage' = 'EXTRA_CHARGE_PER_PAGE',
  'allowPreview' = 'ALLOW_PREVIEW',
  'allowAddElements' = 'ALLOW_ADD_ELEMENTS',
  'allowRemoveElements' = 'ALLOW_REMOVE_ELEMENTS',
  'allowElementLockControl' = 'ALLOW_ELEMENT_LOCK_CONTROL',
  'preventImageElementDeletion' = 'PREVENT_IMAGE_ELEMENT_DELETION',
  'addEmptyLayout' = 'EDITOR_EMPTY_LAYOUT',
  'enableMirroring' = 'ENABLE_MIRRORING',
  'showClipArtSearch' = 'SHOW_CLIPART_SEARCH',
  'DisplayWarningPopupWhenEmptyDesign' = 'DISPLAY_WARNING_POP_UP_WHEN_EMPTY_DESIGN',
  'BlockPopupWhenEmptyDesign' = 'BLOCK_POPUP_WHEN_EMPTY_DESIGN',
  'limitMugPrintArea' = 'LIMIT_MUG_PRINT_AREA',
}

export enum MESSAGE_TYPE {
  'editor.ready' = 'editor.ready',
  'editor.setSidebarTabs' = 'editor.setSidebarTabs',
  'editor.anyClick' = 'editor.anyClick',
  'editor.setSidebarActiveTabName' = 'editor.setSidebarActiveTabName',
  'editor.setTranslations' = 'editor.setTranslations',
  'addons.requestAddons' = 'addons.requestAddons',
  'addons.setAddons' = 'addons.setAddons',
  'editor.setFormatsData' = 'editor.setFormatsData',
  'editor.setFormatSelectorValue' = 'editor.setFormatSelectorValue',
  'editor.formatChangeRequested' = 'editor.formatChangeRequested',
  'layouts.setSchemas' = 'layouts.setSchemas',
  'addons.setAddonsInUse' = 'addons.setAddonsInUse',
  'editor.setTrackDesignChanges' = 'editor.setTrackDesignChanges',
  'editor.requestUserConfirmation' = 'editor.requestUserConfirmation',
  'editor.applyLayout' = 'editor.applyLayout',
  'editor.setEditorHostSettings' = 'editor.setEditorHostSettings',
  'design.setDesignData' = 'design.setDesignData',
  'product.productUidChanged' = 'product.productUidChanged',
  'design.spreadBackgrounds' = 'design.spreadBackgrounds',
  'design.spreadForegrounds' = 'design.spreadForegrounds',
  'design.changeFormat' = 'design.changeFormat',
  'design.formatChanged' = 'design.formatChanged',
  'design.requestDesignData' = 'design.requestDesignData',
  'design.structureChanged' = 'design.structureChanged',
  'design.spreadsCountChanged' = 'design.spreadsCountChanged',
  'success.LoadingDesignSuccess' = 'success.LoadingDesignSuccess',
  'host.saveToDrafts' = 'host.saveToDrafts',
  'host.addToBasket' = 'host.addToBasket',
  'host.displayPreview' = 'host.displayPreview',
  'host.leaveEditor' = 'host.leaveEditor',
  'uploader.previewGeneratedSuccess' = 'uploader.previewGeneratedSuccess',
  'uploader.previewGeneratedRemotelySuccess' = 'uploader.previewGeneratedRemotelySuccess',
  'uploader.previewGeneratedError' = 'uploader.previewGeneratedError',
  'uploader.uploadSuccess' = 'uploader.uploadSuccess',
  'uploader.uploadError' = 'uploader.uploadError',
  'uploader.open' = 'uploader.open',
  'uploader.uploadStart' = 'uploader.uploadStart',
  'uploader.previewGeneratedRemotelyErrorError' = 'uploader.previewGeneratedRemotelyErrorError',
  'uploader.retryUpload' = 'uploader.retryUpload',
  'uploader.retryAll' = 'uploader.retryAll',
  'uploader.removeFile' = 'uploader.removeFile',
  'uploader.uploadProgress' = 'uploader.uploadProgress',

  'uploader.assetUploaded' = 'uploader.assetUploaded',
  'uploader.setConfig' = 'uploader.setConfig',
  'uploader.uploadStarted' = 'uploader.uploadStarted',
  'uploader.uploadComplete' = 'uploader.uploadComplete',

  'gallery.loadUploadedImages' = 'gallery.loadUploadedImages',
  'host.updateOrderItem' = 'host.updateOrderItem',
  'template.setCanAddPages' = 'template.setCanAddPages',
  'template.setForcedMinPageCount' = 'template.setForcedMinPageCount',
  'design.setAutoApplyLayoutSchemaNames' = 'design.setAutoApplyLayoutSchemaNames',
  'preview.setFlatPreviewAvailable' = 'preview.setFlatPreviewAvailable',
  'preview.setThe3dPreviewAvailable' = 'preview.setThe3dPreviewAvailable',
  'preview.setRenders' = 'preview.setRenders',
  'preview.loadPreviews' = 'preview.loadPreviews',
  'preview.rendersError' = 'preview.rendersError',
  'addons.setAddonsAttributionImage' = 'addons.setAddonsAttributionImage',
  'host.changeBundledProduct' = 'host.changeBundledProduct',
  'plugins.setPlugins' = 'plugins.setPlugins',
  'editor.dimensionChangeRequested' = 'editor.dimensionChangeRequested',
  'editor.showWallpaperDimensionsInputInFormats' = 'editor.showWallpaperDimensionsInputInFormats',
  'editor.setLocale' = 'editor.setLocale',
}

export enum EditorTabNames {
  PRODUCT = 'PRODUCT',
  GALLERY = 'GALLERY',
  SETTINGS = 'SETTINGS',
  WARNINGS = 'WARNINGS',
  LAYERS = 'LAYERS',
  CROP = 'CROP',
  PROPERTIES = 'PROPERTIES',
  FONT_FAMILIES = 'FONT_FAMILIES',
  FONT_COLORS = 'FONT_COLORS',
  LAYOUTS = 'LAYOUTS',
  ADDONS = 'ADDONS',
  TEXTS = 'TEXTS',
  FORMATS = 'FORMATS',
}

export interface Message {
  type: MESSAGE_TYPE;
  data?: any;
  callback?: Message;
}

export enum EditorPopupHash {
  uploader = '#uploader',
  bundledProduct = '#bundled',
}
