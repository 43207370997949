import { AnyAction } from 'redux';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { setDesignIsSavingAction, setDesignIsLoadingAction } from 'src/store/design/slice';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import getProductId from 'src/store/design/selector/getProductId';
import getDesignId from 'src/store/design/selector/getDesignId';
import showPopupOperation from './showPopupOperation';

const saveToDraftsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  if (isDesignSaving(store)) {
    return;
  }

  const integrationLayer = useIntegrationLayer();
  gtmSendEvent({
    event: 'save_to_drafts',
    productCategory: integrationLayer.categoryName,
    locale: integrationLayer.locale,
    productId: getProductId(store),
    designId: getDesignId(store),
    editor: 'apiEditor',
  });

  try {
    dispatch(setDesignIsSavingAction(true));
    dispatch(setDesignIsLoadingAction(true));
    const designUpdateResult = await dispatch(updateCustomerDesignOperation());
    if (!designUpdateResult) {
      dispatch(showPopupOperation('UpdateDesignWarningPopup'));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot save design to drafts', e);
  }
  dispatch(setDesignIsLoadingAction(false));
  dispatch(setDesignIsSavingAction(false));
};

export default saveToDraftsOperation;
