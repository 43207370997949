import { cloneDeep } from 'lodash';
import { DesignData } from 'src/store/design/types';

const adjustStudioSelectionDesignStructure = (design: DesignData) => {
  const result = cloneDeep(design) as DesignData;
  try {
    result.spreads[0].layoutSchemaName = 'ss_i1_1';
    result.spreads[0].pages[0].layoutSchemaName = 'ss_i1_1';
    const images = result.spreads[0].pages[0].groups.media;
    if (images) {
      images[0].locked = true;
      images[0].personalizationLocked = true;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error adjust studio selection structure');
  }
  return result;
};

export default adjustStudioSelectionDesignStructure;
