import React from 'react';
import DesignFormat from 'src/component/TopBar/CenterSection/DesignFormat';
import DesignPrice from 'src/component/TopBar/CenterSection/DesignPrice';
import DesignTitle from 'src/component/TopBar/CenterSection/DesignTitle';
import Selectors from 'src/component/TopBar/CenterSection/Selectors';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownMenuEnum } from 'src/store/app/types';
import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import { Store } from 'src/store';
import getUsedClipArts from 'src/store/addon/selector/getUsedClipArts';
import getOpenedDropdownMenu from 'src/store/app/selector/getOpenedDropdownMenu';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import { isApparelProduct } from 'src/util/product/productTypeIdsHelper';
import ProductAttributes from './ProductAttributes';
import styles from './index.module.scss';
import DesignBreakdownPrices from './DesignBreakdownPrices';
import DetailsDropdownMenu from './DesignBreakdownPrices/DetailsDropdownMenu';

interface Props {
  isMobile: boolean;
}

const CenterSection = ({ isMobile }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stickers = useSelector((store: Store) => getUsedClipArts(store));
  const isDropdownVisible = useSelector((store: Store) => getOpenedDropdownMenu(store) === DropDownMenuEnum.stickersDropdown);
  const productTypeId = useSelector(getProductTypeId) || 1;

  const onShowAllHandler = () => {
    dispatch(setOpenedDropdownMenuOperation(DropDownMenuEnum.stickersDropdown));
  };

  return (
    <div className={styles.centerSection}>
      {isMobile && (
        <div className={cn(styles.row, styles.baseInfoMobile)}>
          <DesignTitle isMobile={isMobile} />
        </div>
      )}
      {!isMobile && (
        <div className={cn(styles.row, styles.baseInfo)}>
          <DesignTitle />
          <div className={styles.attributes}>
            <ProductAttributes />
          </div>
        </div>
      )}
      <div className={styles.row}>
        <DesignFormat />
        <Selectors />
        {isMobile && (
          <div className={cn(styles.row, styles.infoContainer)}>
            <span className={styles.totalPrice}>{`${t('txt_price_total')}:`}</span>
            <DesignPrice className={styles.designPrice} />
            <button className={styles.showAll} onClick={onShowAllHandler}>
              <span>{`${t('txt_all_details')}`}</span>
              {isDropdownVisible ? <BsChevronUp /> : <BsChevronDown />}
            </button>
            {isApparelProduct(productTypeId) && (
              <>
                <DetailsDropdownMenu isMobile={isMobile} stickers={stickers}>
                  <div>
                    <ProductAttributes />
                    <DesignBreakdownPrices isMobile={isMobile} />
                  </div>
                </DetailsDropdownMenu>
              </>
            )}
            {!isApparelProduct(productTypeId) && (
              <DetailsDropdownMenu isMobile={isMobile}>
                <div className={styles.productAttributesMobile}>
                  <ProductAttributes />
                </div>
              </DetailsDropdownMenu>
            )}
          </div>
        )}
      </div>
      {!isMobile && (
        <div className={styles.column}>
          <div className={styles.totalPriceDesktop}>
            <span className={styles.totalPrice}>{`${t('txt_price_total')}:`}</span>
            <DesignPrice className={styles.designPrice} />
          </div>
          {isApparelProduct(productTypeId) && (
            <div className={styles.row}>
              <DesignBreakdownPrices isMobile={isMobile} />
              <div className={styles.dropdown}>
                {stickers.length > 0 && (
                  <button className={styles.showAll} onClick={onShowAllHandler}>
                    <span>{`${t('txt_show_all')} (${stickers.length})`}</span>
                    {isDropdownVisible ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                )}
                <DetailsDropdownMenu isMobile={isMobile} stickers={stickers} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CenterSection;
