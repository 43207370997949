import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { opApiRequest } from 'src/api/apiRequest';
import opEndpoints from 'src/api/opEndpoints';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import getProductId from 'src/store/design/selector/getProductId';
import { Store } from 'src/store/index';
import prefillPriceForFormatTabDataOperation from 'src/store/price/operation/prefillPriceForFormatTabDataOperation';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';
import generateFormatTabData from 'src/util/formats/generateFormatTabData';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import cleanupOptionsFromEmptyDescription from 'src/util/formats/cleanupOptionsFromEmptyDescription';
import getFormatSelectorValues from '../selector/getFormatSelectorValues';

const sendFormatsTabDataPostMessageOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const integrationLayer = useIntegrationLayer();
  const productId = getProductId(store);

  if (!productId) return;

  const editorFormatSelectorValues = getFormatSelectorValues(store);
  const bundledProducts = getProductBundleSelectedProducts(store);
  let formatsTabData = generateFormatTabData(integrationLayer, productId, editorFormatSelectorValues, bundledProducts);

  if (!formatsTabData) return;

  const sizeSelector = formatsTabData.selectors.find((selector) => selector.typeName === 'GarmentSize');
  if (sizeSelector) {
    const productData = await opApiRequest(
      opEndpoints.PRODUCT_v1_CONTENT_GET,
      {
        productId,
      },
      'data',
      'GET',
    ) as AppBundle.Api.Response.Product.V1.ProductContentGetV1Response;

    if (productData.content.productSizeDetailsImage) {
      const sizeGuide = {
        title: productData.content.title,
        description: productData.content.productDetailsPopup,
        images: [{
          image: productData.content.productSizeDetailsImage,
          title: '',
        }],
      };
      (sizeSelector as any).sizeGuide = sizeGuide;
    }
  }

  formatsTabData = await dispatch(prefillPriceForFormatTabDataOperation(formatsTabData));
  sendPostMessage({
    type: MESSAGE_TYPE['editor.setFormatsData'],
    data: cleanupOptionsFromEmptyDescription(formatsTabData.selectors),
  });
};

export default sendFormatsTabDataPostMessageOperation;
