import { DesignData, GroupElement, MediaBox } from 'src/store/design/types';

const adjustMugBlankArea = (designDataToModify: DesignData, mediaBoxDefinition: MediaBox, blankOnLeft: boolean) => {
  // blank on left side of mug
  let x = 0;
  let y = 0;
  let x1 = mediaBoxDefinition.x || 0;
  let y1 = mediaBoxDefinition.y + mediaBoxDefinition.height || 0;

  // blank on right side of blank
  if (!blankOnLeft) {
    x = (mediaBoxDefinition.x || 0) + mediaBoxDefinition.width;
    y = 0;
    x1 = x + mediaBoxDefinition.width;
    y1 = y + mediaBoxDefinition.height;
  }
  try {
    designDataToModify.spreads[0].pages[0].groups.blank = [
      {
        type: 'area',
        name: 'Blank Page',
        group: 'Blank',
        x,
        y,
        width: x1 - x,
        height: y1 - y,
        extra: {},
        path: `M${x},${y} H${x1} V${y1} H${x} V${y} Z`,
      },
      {
        type: 'line',
        name: 'Blank Page Indicator',
        group: 'Blank',
        x1: x,
        y1: y,
        x2: x1,
        y2: y1,
        extra: {},
      },
      {
        type: 'line',
        name: 'Blank Page Indicator',
        group: 'Blank',
        x1: x,
        y1,
        x2: x1,
        y2: y,
        extra: {},
      },
    ] as GroupElement[];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error: adjusting blank area');
  }
};

export default adjustMugBlankArea;
