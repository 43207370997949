import {
  LayoutFrameArea, LayoutOffset, LayoutPadding, LayoutSchema, LayoutShift,
} from './layoutTypes';
import numberToOffset from './numberToOffset';
import numberToPadding from './numberToPadding';

const defineLayoutSchema = (
  name: string,
  cols: number,
  rows: number,
  shiftSteps: number,
  spacing: number | LayoutOffset,
  padding: number | LayoutPadding,
  offset: number | LayoutPadding,
  shifting: undefined | LayoutShift,
  allowForSpreadWithPages: number,
  frameRows: (LayoutFrameArea[])[],
  useBleed = false,
) => ({
  name,
  params: {
    cols,
    rows,
    shiftSteps,
    spacing: typeof spacing === 'number' ? numberToOffset(spacing) : spacing,
    padding: typeof padding === 'number' ? numberToPadding(padding) : padding,
    offset: typeof offset === 'number' ? numberToPadding(offset) : offset,
    shifting,
    useBleed,
    useContentAreasOnly: useBleed,
  },
  allowForSpreadWithPages,
  frameRows,
} as LayoutSchema);

export default defineLayoutSchema;
