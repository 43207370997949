import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import { setAgreeWithLowDPIAction } from 'src/store/design/slice';
import shouldDisplayEmptyDesignWarning from '../selector/shouldDisplayEmptyDesignWarning';
import { registerCustomerAction } from '../slice';
import sendRequestDesignDataPostMessageToAddToBasketOperation from './sendRequestDesignDataPostMessageToAddToBasketOperation';
import showPopupOperation from './showPopupOperation';

const initAddToBasketOperation = () => async (dispatch: ThunkDispatch<Store, undefined, AnyAction>, getState: () => Store) => {
  const store = getState();
  dispatch(setAgreeWithLowDPIAction(false));

  const shouldDisplayPopup = shouldDisplayEmptyDesignWarning(store);
  if (shouldDisplayPopup) {
    dispatch(showPopupOperation('EmptyDesignWarningPopup'));
    dispatch(registerCustomerAction('EmptyDesignWarningPopupShown'));
  } else {
    dispatch(sendRequestDesignDataPostMessageToAddToBasketOperation());
  }
};

export default initAddToBasketOperation;
