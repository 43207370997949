import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getProductUid from 'src/store/design/selector/getProductUid';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import isDesignHasEmptyContent from 'src/util/design/isDesignHasEmptyContent';
import getStructure from 'src/store/design/selector/getStructure';
import getDesignId from 'src/store/design/selector/getDesignId';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import getImagesCount from 'src/store/gallery/selector/getImagesCount';
import getAppStartTime from 'src/store/app/selector/getAppStartTime';
import getAppSessionId from 'src/store/app/selector/getAppSessionId';
import getCustomerActionsChain from 'src/store/app/selector/getCustomerActionsChain';

const trackEventOperation = (event: string) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  try {
    const integrationLayer = useIntegrationLayer();
    const store = getState();
    const isMobile = getIsMobile(store);
    const productUid = getProductUid(store);
    const productTypeId = getProductTypeId(store);
    const { locale } = integrationLayer;
    const { userAgent } = navigator;
    const isEmptyDesign = isDesignHasEmptyContent(getStructure(store));
    const designId = getDesignId(store);
    const imagesInGallery = getImagesCount(store);
    const timeLine = new Date().getTime() - getAppStartTime(store);
    const eventTime = new Date().toUTCString();
    const actionsChain = getCustomerActionsChain(store).join(',');
    const appSessionId = getAppSessionId(store);

    gtmSendEvent({
      event,
      isMobile,
      productTypeId,
      productUid,
      locale,
      userAgent,
      isEmptyDesign,
      designId,
      imagesInGallery,
      eventTime,
      timeLine,
      actionsChain,
      appSessionId,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export default trackEventOperation;
