import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { Feature, MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import isAttributeSelected from 'src/store/design/selector/isAttributeSelected';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import getAttributeValue from 'src/store/design/selector/getAttributeValue';
import { checkSimplePhotoMug } from 'src/util/product/productTypeIdsHelper';

const sendSetAutoApplyLayoutSchemaNamePostMessageOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getStore: () => Store,
) => {
  const store = getStore();
  let { defaultLayout } = useIntegrationLayer();
  const { productTypeId } = useIntegrationLayer();

  const isWrapAroundMug = isAttributeSelected(store, 'OpMugPrintArea', 'wrap-around');
  const isSimplePhotoMug = checkSimplePhotoMug(getAttributeValue(store, 'OpMugPrintArea') || '');
  const isWallpaperCustomProduct = productTypeId === 177;

  if (isWrapAroundMug) {
    defaultLayout = ['mwa_i1_1', 'mwa_i1_1'];
  }
  if (isSimplePhotoMug) {
    defaultLayout = ['msph_i1_1', 'msph_i1_1'];
  }
  if ((isWrapAroundMug || isSimplePhotoMug) && isFeatureEnabled(Feature.addEmptyLayout)) {
    defaultLayout = ['empty_1', 'empty_1'];
  }

  if (isWallpaperCustomProduct) {
    defaultLayout = ['ss_i1_1'];
  }

  if (defaultLayout && defaultLayout.length) {
    sendPostMessage({
      type: MESSAGE_TYPE['design.setAutoApplyLayoutSchemaNames'],
      data: defaultLayout,
    });
  }
};

export default sendSetAutoApplyLayoutSchemaNamePostMessageOperation;
