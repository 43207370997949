import { DesignData } from 'src/store/design/types';

const dpiWarningsExists = (design?: DesignData) => {
  if (!design || !design.warnings) {
    return false;
  }
  return design.warnings.some((warning) => warning.type === 'LowDPI');
};

export default dpiWarningsExists;
