import { AppBundle } from 'src/api/optimalprint-sdk.d';

type FormatsTabOption = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector.IProductPageSelectorOption & {
  description: string;
}
type FormatTypeSelector = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector & {
  options: FormatsTabOption[];
};

const cleanupOptionsFromEmptyDescription = (selectors: AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector[]) => {
  (selectors as FormatTypeSelector[]).forEach((selector) => {
    selector.options.forEach((option: FormatsTabOption) => {
      if (option.description === option.name) {
        option.description = '';
      }
    });
  });
  return selectors;
};

export default cleanupOptionsFromEmptyDescription;
