import { AnyAction } from 'redux';
import { DesignData } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import gtmSendLowDpiWarningEvent from 'src/util/gtm/gtmSendLowDpiWarningEvent';
import getProductUid from '../selector/getProductUid';
import getProductTypeId from '../selector/getProductTypeId';

const sendGtmLowDpiWarningOperation = (structure: DesignData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const productUid = getProductUid(store);
  const productTypeId = getProductTypeId(store);
  gtmSendLowDpiWarningEvent(structure, productUid, productTypeId);
};

export default sendGtmLowDpiWarningOperation;
