import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getDesignId from 'src/store/design/selector/getDesignId';
import getEncPublicDesignId from 'src/store/design/selector/getEncPublicDesignId';
import getProductId from 'src/store/design/selector/getProductId';
import getStructure from 'src/store/design/selector/getStructure';
import isPublicDesign from 'src/store/design/selector/isPublicDesign';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getDesignContentHash from 'src/util/design/getDesignContentHash';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const updateCustomerDesignOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  if (isPublicDesign(store)) {
    return false;
  }

  const {
    clientPlatform,
  } = useIntegrationLayer();

  const structure = getStructure(store);
  const designId = getDesignId(store);
  const categoryId = getCategoryId(store);
  const encPublicDesignId = getEncPublicDesignId(store);
  const productId = getProductId(store);
  const abstractProduct = getAbstractProduct(store);

  if (!structure || !designId || !categoryId) {
    return false;
  }
  const contentHash = getDesignContentHash(structure);

  const requestData = {
    customerDesignId: designId,
    categoryId,
    structure,
    clientPlatform,
    isDesignCompleted: true,
    contentHash,
    encPublicDesignId,
    productId: abstractProduct ? productId : null,
  };

  try {
    await opApiRequest(
      endpoints.CUSTOMER_DESIGN_V3_UPDATE,
      requestData,
      'data',
      'POST',
    );
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot update customer design', e);
    return false;
  }
};

export default updateCustomerDesignOperation;
