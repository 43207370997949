import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerOval from 'src/component/Icon/SpinnerOval/SpinnerOval';
import Popup from 'src/component/Popup';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import sendRequestDesignDataPostMessageToAddToBasketOperation from 'src/store/app/operation/sendRequestDesignDataPostMessageToAddToBasketOperation';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getProductUid from 'src/store/design/selector/getProductUid';
import { setAgreeWithLowDPIAction } from 'src/store/design/slice';
import gtmLowDPIWarningEvents from 'src/util/gtm/gtmLowDPIWarningEvents';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import styles from './LowDPIWarningPopup.module.scss';

const LowDPIWarningPopup = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const productUid = useSelector(getProductUid);
  const productTypeId = useSelector(getProductTypeId);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isAttentionToCheckbox, setIsAttentionToCheckbox] = useState(false);
  const [isOkWithQuality, setIsOkWithQuality] = useState(false);
  const [isExpandTips, setIsExpandTips] = useState(() => !isMobile);

  const toggleTips = () => {
    if (!isMobile) return;
    setIsExpandTips((v) => !v);
  };

  const onBackToEditing = () => {
    dispatch(hidePopupOperation());
    onClosePopup();
  };

  const qualityCheckboxClick = () => {
    setIsAttentionToCheckbox(false);
    setIsOkWithQuality(!isOkWithQuality);
  };

  const onClosePopup = () => {
    gtmSendEvent(gtmLowDPIWarningEvents('reject', productUid, productTypeId));
  };

  const onShowPopup = () => {
    gtmSendEvent(gtmLowDPIWarningEvents('show', productUid, productTypeId));
  };

  const onAddToBasket = () => {
    if (!isOkWithQuality) {
      setIsAttentionToCheckbox(true);
      return;
    }
    gtmSendEvent(gtmLowDPIWarningEvents('accept', productUid, productTypeId));
    dispatch(setAgreeWithLowDPIAction(true));
    setIsLoading(true);
    dispatch(sendRequestDesignDataPostMessageToAddToBasketOperation());
    setTimeout(() => setIsLoading(false), 30000);
  };

  const renderActions = () => (
    <div className={styles.actions}>
      {isLoading ? (
        <SpinnerOval />
      ) : (
        <>
          <op-button block variant="primary" class={styles.action} onClick={onBackToEditing}>
            {t('low_dpi_image.modal.button.back_to_editing')}
          </op-button>
          <op-button block variant="secondary" class={styles.action} onClick={onAddToBasket}>
            {t('opeh_top-bar_add-to-basket')}
          </op-button>
        </>
      )}
    </div>
  );

  const renderTips = () => (
    <div className={styles.tips}>
      <div className={styles.tipsHeader} onClick={toggleTips}>
        <op-text variant={isMobile ? 'body' : 'title'} size={isMobile ? 'large' : 'medium'} weight="bold">{t('low_dpi_image.modal.tips.title')}</op-text>
        {isMobile && <op-icon size="x-small" icon={isExpandTips ? 'caret-up' : 'caret-down'} />}
      </div>
      {isExpandTips && (
        <>
          <op-text variant="body" size="large" class={styles.tipWithIcon}>
            <div className={styles.circle}>
              <op-icon icon="retry" size="small" />
            </div>
            {t('low_dpi_image.modal.tips.first')}
          </op-text>
          <op-text variant="body" size="large" class={styles.tipWithIcon}>
            <div className={styles.circle}>
              <op-icon icon="reduce" size="small" />
            </div>
            {t('low_dpi_image.modal.tips.second')}
          </op-text>
        </>
      )}
    </div>
  );

  const renderConfirmation = () => (
    <div className={cn(styles.confirmation, { [styles.highlight]: isAttentionToCheckbox })}>
      <op-checkbox
        variant={isAttentionToCheckbox ? 'error' : 'primary'}
        checked={isOkWithQuality || undefined}
        size="small"
        onClick={qualityCheckboxClick}
      />
      <div>
        <op-text
          variant="body"
          size={isMobile ? 'medium' : 'large'}
          onClick={qualityCheckboxClick}
          color={isAttentionToCheckbox ? 'destructive-70' : 'neutral-90'}
        >
          {t('low_dpi_image.modal.checkbox.title')}
        </op-text>
        <div>
          <op-text
            variant="body"
            size={isMobile ? 'small' : 'medium'}
            color="neutral-70"
          >
            {t('low_dpi_image.modal.checkbox.subtitle')}
          </op-text>
        </div>
      </div>
    </div>
  );

  const renderContent = () => (
    <div className={styles.message}>
      <op-text variant="body" size={isMobile ? 'medium' : 'large'}>{t('low_dpi_image.modal.subtitle')}</op-text>
      {renderTips()}
      {renderConfirmation()}
      {isAttentionToCheckbox && (
        <div className={styles.highlight}>
          <op-text variant="body" size={isMobile ? 'small' : 'large'} color="destructive-70">{t('low_dpi_image.modal.error_message')}</op-text>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.popupWrapper}>
      <Popup popupId="LowDPIWarningPopup" rounded onShowPopup={onShowPopup} onClosePopup={onClosePopup} showCloseOnMobile mobileFloat>
        <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
          <div className={styles.warning}>
            <op-icon icon="warning-outline" size="large" />
            <op-text variant="title" size="large" weight="bold">{t('txt_warning')}</op-text>
          </div>
          {renderContent()}
          {renderActions()}
        </div>
      </Popup>
    </div>
  );
};

export default LowDPIWarningPopup;
