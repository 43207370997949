import { AnyAction } from 'redux';
import { FormatData } from 'src/store/format/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setCurrentFormatAction } from 'src/store/format/slice';
import {
  setDesignIsLoadingAction,
  setDesignEncPublicIdAction,
  setDesignProductIdAction,
  setDesignCategoryIdAction,
  setDesignProductUidAction,
  setDesignInternalUidAction,
} from '../slice';
import updateProductAttributesOperaion from './updateProductAttributesOperaion';

const designFormatChangedOperation = (format: FormatData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(setDesignProductUidAction(format.productUid));
  dispatch(setDesignInternalUidAction(format.internalUid));
  dispatch(updateProductAttributesOperaion());
  dispatch(setDesignEncPublicIdAction(format.encPublicDesignId));
  dispatch(setDesignProductIdAction(format.productId));
  dispatch(setDesignCategoryIdAction(format.categoryId));
  dispatch(setCurrentFormatAction(format));
  dispatch(setDesignIsLoadingAction(false));
};

export default designFormatChangedOperation;
