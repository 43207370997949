const hideMobileMenuBar = () => {
  if (!window.integrationLayer || !window.integrationLayer.isMobile) return;
  const menuElement = document.getElementsByClassName('op-mobile-header-line');
  if (menuElement && menuElement[0]) {
    menuElement[0].classList.add('hide');
  }
  const searchBarElement = document.getElementsByClassName('quick-search-bar');
  if (searchBarElement && searchBarElement[0]) {
    searchBarElement[0].classList.add('hide');
  }
};

export default hideMobileMenuBar;
