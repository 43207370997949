import React from 'react';
import DropdownMenu from 'src/component/DropdownMenu';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store';
import getOpenedDropdownMenu from 'src/store/app/selector/getOpenedDropdownMenu';
import { DropDownMenuEnum } from 'src/store/app/types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './index.module.scss';
import StickersPricing from '../StickersPricing';

interface Props {
  isMobile: boolean;
  stickers?: apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt[];
  children?: React.ReactNode;
}

const DetailsDropdownMenu = ({ stickers = [], isMobile, children }: Props) => {
  const isDropdownVisible = useSelector((store: Store) => getOpenedDropdownMenu(store) === DropDownMenuEnum.stickersDropdown);

  return (
    <DropdownMenu
      isVisible={isDropdownVisible}
      className={cn(styles.dropdownContainer, {
        [styles.positionMobile]: isMobile,
        [styles.positionDesktop]: !isMobile,
      })}
    >
      {children && <div className={styles.children}>{children}</div>}
      {stickers && <StickersPricing stickers={stickers} className={styles.dropdown} />}
    </DropdownMenu>
  );
};

export default DetailsDropdownMenu;
