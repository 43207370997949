import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getPrice from 'src/store/price/selector/getPrice';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';
import { Price } from 'src/store/price/types';
import getProductBundleSelectedProducts from '../selector/getProductBundleSelectedProducts';
import { setProductBundleSelectedProductsTotalPriceAction } from '../slice';

const updateBundledProductsPriceOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  let store = getState();
  const categoryId = getCategoryId(store) || 1;
  const quantity = 1;
  const selectedProducts = getProductBundleSelectedProducts(store);
  const missingPriceProductIds: number[] = [];
  selectedProducts.forEach((selectedProduct) => {
    if (selectedProduct.productId && !getPrice(store, categoryId, selectedProduct.productId, quantity)) {
      missingPriceProductIds.push(selectedProduct.productId);
    }
  });

  if (missingPriceProductIds.length) {
    await dispatch(fetchPricesOperation(categoryId, missingPriceProductIds));
    store = getState();
  }

  const totalPrice: Price = {
    priceInitial: 0,
    price: 0,
  };

  selectedProducts.forEach((selectedProduct) => {
    if (!selectedProduct.productId) {
      return;
    }
    const price = getPrice(store, categoryId, selectedProduct.productId, quantity);
    if (!price) {
      // eslint-disable-next-line no-console
      console.error('No price for productId ', selectedProduct.productId);
      return;
    }
    totalPrice.priceInitial += price.pi;
    totalPrice.price += price.p;
  });
  dispatch(setProductBundleSelectedProductsTotalPriceAction(totalPrice));
};

export default updateBundledProductsPriceOperation;
