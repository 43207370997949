import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';

const sendEditorShowDoubleLinesPostMessageOperation = (callback?: any) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  sendPostMessage({
    type: MESSAGE_TYPE['editor.setEditorHostSettings'],
    data: {
      showDoubleLine: true,
    },
    callback,
  });
};

export default sendEditorShowDoubleLinesPostMessageOperation;
