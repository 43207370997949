import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { Message, MESSAGE_TYPE } from 'src/store/app/types';
import { AppDispatch } from 'src/store/index';

const sendRequestDesignDataPostMessageOperation = (callback?: Message, checkWarnings?: boolean) => (dispatch: AppDispatch) => sendPostMessage({
  type: MESSAGE_TYPE['design.requestDesignData'],
  data: checkWarnings
    ? {
      checkWarnings: [{
        type: 'IMAGE_CORRUPTED',
        level: ['HIGH'],
      }],
    }
    : undefined,
  callback,
});

export default sendRequestDesignDataPostMessageOperation;
