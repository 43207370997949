import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import i18n from 'src/i18n';

const NOT_EXISTING_PREFIX = '{@!#';
const trValueAsIs = (key: string) => i18n.t(key, { interpolation: { prefix: NOT_EXISTING_PREFIX, suffix: NOT_EXISTING_PREFIX } });

const sendSetEditorTranslationsPostMessageOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const overrideEditorTranslation = {
    'editor-average-quality': trValueAsIs('editor-average-quality'),
    'editor-poor-quality': trValueAsIs('editor-poor-quality'),
    'editor-average-quality-info': trValueAsIs('editor-average-quality-info'),
    'editor-poor-quality-info': trValueAsIs('editor-poor-quality-info'),
  };

  sendPostMessage({
    type: MESSAGE_TYPE['editor.setTranslations'],
    data: overrideEditorTranslation,
  });
};

export default sendSetEditorTranslationsPostMessageOperation;
