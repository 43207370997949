import { DesignData } from 'src/store/design/types';

const lowDpiWarningsExists = (design?: DesignData) => {
  if (!design || !design.warnings) {
    return false;
  }
  const lowDpi = design.resolution?.dpi.min;
  if (!lowDpi) {
    return false;
  }

  return design.warnings.some((warning) => warning.type === 'LowDPI' && warning.dpi < lowDpi);
};

export default lowDpiWarningsExists;
