import React, { HTMLAttributes } from 'react';
import apiTypes from 'src/api/optimalprint-sdk.d';
import cn from 'classnames';
import styles from './index.module.scss';
import StickersWithPrice from '../StickerWithPrice';

interface Props extends HTMLAttributes<HTMLDivElement> {
  stickers?: apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt[];
}

const StickersPricing = ({ stickers, className }: Props) => (
  <div className={cn(styles.container, className)}>
    {stickers && stickers.map((sticker) => (
      <StickersWithPrice sticker={sticker} key={sticker.clipArtId} />
    ))}
  </div>
);

export default StickersPricing;
