import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import { Attribute } from 'src/store/design/types';
import getCurrentProduct from '../selector/getCurrentProduct';
import { setDesignSelectedProductAttributesAction } from '../slice';

const updateProductAttributesOperaion = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const product = getCurrentProduct(store);
  const attrObj: { [typeName: string]: Attribute } = {};
  product?.attributes.forEach((attribute) => {
    attrObj[attribute.typeName] = {
      typeName: attribute.typeName,
      value: attribute.value,
    };
  });
  dispatch(setDesignSelectedProductAttributesAction(attrObj));
};

export default updateProductAttributesOperaion;
