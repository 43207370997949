import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const sendSetTemplateForcedMinPageCountPostMessageOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const { forcedMinPageCount } = useIntegrationLayer();
  sendPostMessage({
    type: MESSAGE_TYPE['template.setForcedMinPageCount'],
    data: forcedMinPageCount,
  });
};

export default sendSetTemplateForcedMinPageCountPostMessageOperation;
