import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Store } from 'src/store/';
import isPopupActive from 'src/store/app/selector/isPopupActive';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import { BsX } from 'react-icons/bs';
import styles from './index.module.scss';

interface OwnProps {
  popupId: string;
  rounded?: boolean;
  showCloseOnMobile?: boolean;
  title?: string;
  applyButtonTitle?: string;
  children: React.ReactNode;
  onClosePopup?: () => void;
  onShowPopup?: () => void;
  onApplyClick?: () => void;
  mobileFloat?: boolean;
}

interface Props extends OwnProps {}

const Popup = ({
  popupId,
  rounded,
  showCloseOnMobile,
  mobileFloat,
  title,
  applyButtonTitle,
  children,
  onClosePopup,
  onShowPopup,
  onApplyClick,
}: Props) => {
  const isActive = useSelector((store: Store) => isPopupActive(store, popupId));
  const isMobile = useSelector(getIsMobile);
  const dispatch = useDispatch();

  if (isActive) {
    document.documentElement.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  const applyClick = () => {
    if (onApplyClick) onApplyClick();
    dispatch(hidePopupOperation());
  };

  const closeClick = () => {
    if (onClosePopup) onClosePopup();
    dispatch(hidePopupOperation());
  };

  useEffect(
    () => () => {
      if (onShowPopup) onShowPopup();
    },
    [isActive],
  );

  if (!isActive) return null;

  const showCloseIcon = !isMobile || showCloseOnMobile;

  return (
    <div
      className={cn(styles.popup, {
        [styles.rounded]: rounded,
        [styles.mobile]: isMobile,
        [styles.mobileFloat]: mobileFloat,
      })}
    >
      {title && (
        <div
          className={cn(styles.header, {
            [styles.desktop]: !isMobile,
          })}
        >
          {title}
        </div>
      )}
      {showCloseIcon && (
        <div className={styles.closeIcon} onClick={closeClick}>
          <BsX size="40px" />
        </div>
      )}
      <div className={styles.popupContent}>{children}</div>
      {applyButtonTitle && (
        <div className={styles.applyButton} onClick={applyClick}>
          {applyButtonTitle}
        </div>
      )}
    </div>
  );
};

export default Popup;
