const localesMap: { [opLocale: string]: string} = {
  'no-NO': 'nb-NO',
  'dk-DK': 'da-DK',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  'de-CH': 'de-DE',
};

const mapLocaleToEditorSupported = (opLocale: string) => (localesMap[opLocale] || opLocale);

export default mapLocaleToEditorSupported;
