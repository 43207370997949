import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import productUidChangedOperation from 'src/store/design/operation/productUidChangedOperation';
import setProductBundleSelectedProductsOperation from 'src/store/productBundle/operation/setProductBundleSelectedProductsOperation';
import sendSetDesignDataPostMessageOperation from 'src/store/app/operation/sendSetDesignDataPostMessageOperation';
import getStructure from 'src/store/design/selector/getStructure';
import updateProductUidInDesignStructureOperation from 'src/store/design/operation/updateProductUidInDesignStructureOperation';

const upgradeProductByUpsellOperation = (productUid: string, internalUid: string, productId: number, bundledProducts: any[]) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  // designFormatChangedOperation
  await dispatch(productUidChangedOperation({
    productUid,
    internalUid,
    productId,
  }));

  // rewrite bundled products
  dispatch(setProductBundleSelectedProductsOperation(bundledProducts));

  // update productUid in design structure
  dispatch(updateProductUidInDesignStructureOperation(productUid));

  // push updated design to the editor
  const designStructure = getStructure(getState());
  dispatch(sendSetDesignDataPostMessageOperation(designStructure));
};

export default upgradeProductByUpsellOperation;
