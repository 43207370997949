import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { Feature } from 'src/store/app/types';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getProductId from 'src/store/design/selector/getProductId';
import { AbstractProduct } from 'src/store/design/types';
import { getAttributeValue } from 'src/util/formats/generateFormatTabData';
import styles from './index.module.scss';

type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;

interface Props {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
  children?: React.ReactElement;
  abstractProduct?: AbstractProduct;
  isFormatsTabAvailable: boolean;
  productId?: number;
}

const ProductAttributes = (
  {
    abstractProduct,
    isFormatsTabAvailable,
    productId,
    children,
  }: Props,
) => {
  if (!abstractProduct || !isFormatsTabAvailable) {
    return children || null;
  }
  const product = abstractProduct.products.find((productItem) => productItem.productId === productId);
  if (!product) return null;

  return (
    <>
      {abstractProduct.editorSelectors.map((selector, index) => {
        if (selector.isHidden) return null;
        const productAttrValue = getAttributeValue(product as any as ProductItem, selector.typeName);
        const productAttrOption = selector.options.find((selectorOption) => selectorOption.value === productAttrValue);
        if (!productAttrOption) return null;
        return (
          <span key={`so${index}`} className={styles.attribute}>
            {productAttrOption.name}
          </span>
        );
      })}
      <span className={styles.children}>
        {children}
      </span>
    </>
  );
};

const mapStateToProps = (store: Store) => ({
  abstractProduct: getAbstractProduct(store),
  productId: getProductId(store),
  isFormatsTabAvailable: isFeatureEnabled(store, Feature.allowFormats),
});

export default connect(mapStateToProps)(ProductAttributes);
