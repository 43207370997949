import {
  LayoutSchema,
} from './layoutTypes';
import defineLayoutSchema from './defineLayoutSchema';

const layoutSchemasStudioSelection: LayoutSchema[] = [
  defineLayoutSchema('ss_i1_1', 1, 1, 10, 0, 0, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
  ], true),
];

export default layoutSchemasStudioSelection;
