import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { DesignData } from 'src/store/design/types';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Price } from 'src/store/price/types';
import collectAllUsedAddons from 'src/util/addon/collectAllUsedAddons';
import calculateUsedClipArtHash from 'src/util/addon/calculateUsedClipArtHash';
import getPrice from 'src/store/price/selector/getPrice';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';
import getUsedClipArtsHash from '../selector/getUsedClipArtsHash';
import fetchClipArtsOperation from './fetchClipArtsOperation';
import { addUsedClipArtsAction, addUsedClipArtsHashAction, addUsedClipArtsPriceAction } from '../slice';

type ProductPrice = apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice;
type ClipArt = apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt;
type AddonProductPrice = [number, ProductPrice];

const calculateUsedClipArtsPriceOperation = (designData: DesignData) => async (dispatch: ThunkDispatch<Store, undefined, AnyAction>, getState: () => Store) => {
  let store = getState();
  const categoryId = getCategoryId(store) || 1;

  const getAddonsPrice = (usedAddons: ClipArt[]) => {
    const missingPricesProductIds: number[] = [];
    const addonPrices: AddonProductPrice[] = [];
    usedAddons.forEach((addon) => {
      const addonPrice = getPrice(store, categoryId, addon.productId, addon.quantity, true);
      if (addonPrice === undefined) {
        missingPricesProductIds.push(addon.productId);
      } else {
        addonPrices.push([addon.clipArtId, addonPrice]);
      }
    });
    return {
      missingPricesProductIds,
      addonPrices,
    };
  };

  const calculatePrice = (usedAddons: ClipArt[], addonPrices: AddonProductPrice[]): Price => {
    let priceSum = 0;
    let priceInitialSum = 0;

    usedAddons.forEach((addon) => {
      const addonPrice = addonPrices.find((ap) => ap[0] === addon.clipArtId);
      if (!addonPrice) {
        // eslint-disable-next-line no-console
        console.error('Price not found for addon', addon);
        return;
      }
      priceSum += addonPrice[1].p;
      priceInitialSum += addonPrice[1].pi;
    });
    return {
      price: priceSum,
      priceInitial: priceInitialSum,
    };
  };

  const calculateAddonsPrice = async (usedAddons: ClipArt[]) => {
    let addonPriceSummary = getAddonsPrice(usedAddons);
    if (addonPriceSummary.missingPricesProductIds.length) {
      await dispatch(fetchPricesOperation(categoryId, addonPriceSummary.missingPricesProductIds));
      store = getState();
      addonPriceSummary = getAddonsPrice(usedAddons);
    }
    if (addonPriceSummary.missingPricesProductIds.length) {
      // eslint-disable-next-line no-console
      console.error('Missing addon prices for productIds', addonPriceSummary.missingPricesProductIds);
    }
    return calculatePrice(usedAddons, addonPriceSummary.addonPrices);
  };

  const usedAddonsBrief = collectAllUsedAddons(designData);
  const usedAddonsHash = calculateUsedClipArtHash(usedAddonsBrief);
  const lastUsedAddonsHash = getUsedClipArtsHash(store);
  const usedAddons = (await dispatch(fetchClipArtsOperation(usedAddonsBrief))) || [];

  if (usedAddonsHash === lastUsedAddonsHash) {
    // do not update used cliparts price, no changes detected
    return;
  }
  const usedAddonsPrice = (await calculateAddonsPrice(usedAddons as ClipArt[])) as Price;
  dispatch(addUsedClipArtsPriceAction(usedAddonsPrice));
  dispatch(addUsedClipArtsHashAction(usedAddonsHash));
  dispatch(addUsedClipArtsAction(usedAddons));
};

export default calculateUsedClipArtsPriceOperation;
