import { PayloadAction } from '@reduxjs/toolkit';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { AddonState } from 'src/store/addon/types';
import update from 'immutability-helper';

const addUsedClipArtsReducer = (
  state: AddonState,
  action: PayloadAction<apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt[]>,
) => {
  if (!action.payload) return state;
  return update<AddonState>(state, {
    usedClipArts: {
      clipArts: {
        $set: action.payload,
      },
    },
  });
};

export default addUsedClipArtsReducer;
