import { DesignData } from 'src/store/design/types';

const removeBlankArea = (designDataToModify: DesignData) => {
  try {
    designDataToModify.spreads[0].pages[0].groups.blank = undefined;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error removing blank area');
  }
};

export default removeBlankArea;
