import type { FileUploaderConfig } from 'file-uploader';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { MESSAGE_TYPE } from '../types';
import sendPostMessage from './sendPostMessage';

const setUploaderSettingsOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const { uploaderCompanionUrl } = useIntegrationLayer();

  const fileUploaderConfig: FileUploaderConfig = {
    core: {
      id: 'uppy-app',
      restrictions: {
        maxNumberOfFiles: 200,
        allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', '.heic'],
      },
      autoProceed: true,
      allowMultipleUploads: true,
    },
    dashboard: {
      disableStatusBar: true,
    },
    statusBar: false,
    companionUrl: uploaderCompanionUrl,
    s3: {
      limit: 5,
    },
  };
  sendPostMessage({
    type: MESSAGE_TYPE['uploader.setConfig'],
    data: fileUploaderConfig,
  });
};

export default setUploaderSettingsOperation;
