import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getDesignId from 'src/store/design/selector/getDesignId';
import isPublicDesign from 'src/store/design/selector/isPublicDesign';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { addGalleryImagesAction } from '../slice';
import sendGalleryUploadedImagesPostMessageOperation from './sendGalleryUploadedImagesPostMessageOperation';

const fetchDesignImagesOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const customerDesignId = getDesignId(store);
  const isPublic = isPublicDesign(store);

  if (isPublic) {
    return;
  }

  const limit = 50;

  try {
    const initialResponse = (await opApiRequest(
      endpoints.CUSTOMER_DESIGN_V1_PHOTO_LIST,
      {
        customerDesignId,
        limit,
        offset: 0,
      },
      'data',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V1.CustomerDesignPhotoListV1Response;

    if (initialResponse) {
      dispatch(addGalleryImagesAction(initialResponse.items));
      dispatch(sendGalleryUploadedImagesPostMessageOperation(initialResponse.items));

      if (initialResponse.total > limit) {
        const iterations = Math.ceil(initialResponse.total / limit);
        const promises = [];

        for (let i = 1; i <= iterations; i += 1) {
          const fetch = async () => {
            try {
              const response = (await opApiRequest(
                endpoints.CUSTOMER_DESIGN_V1_PHOTO_LIST,
                {
                  customerDesignId,
                  limit,
                  offset: limit * i,
                },
                'data',
                'POST',
              )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V1.CustomerDesignPhotoListV1Response;

              if (response) {
                dispatch(addGalleryImagesAction(response.items));
                dispatch(sendGalleryUploadedImagesPostMessageOperation(response.items));
              } else {
                // eslint-disable-next-line no-console
                console.error('Could not fetch initial photo list, response: ', response);
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Could not fetch initial photo list, exception: ', e);
            }
          };
          promises.push(fetch());
        }
        await Promise.all(promises);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Could not fetch initial photo list, response: ', initialResponse);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch initial photo list, exception: ', e);
  }
};

export default fetchDesignImagesOperation;
