import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import getProductUid from 'src/store/design/selector/getProductUid';
import { FormatData } from 'src/store/format/types';
import { Store } from 'src/store/index';
import forceFormatChange from '../selector/forceFormatChange';
import { setDesignIsLoadingAction } from '../slice';

const sendDesignFormatChangePostMessageOperation = (formatData: FormatData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const currentUid = getProductUid(store);
  if (currentUid === formatData.productUid) {
    return;
  }

  const callback = {
    type: MESSAGE_TYPE['design.formatChanged'],
    data: formatData,
  };
  dispatch(setDesignIsLoadingAction(true));
  sendPostMessage({
    type: MESSAGE_TYPE['design.changeFormat'],
    data: {
      productUid: formatData.productUid,
      forceFormatChange: forceFormatChange(store),
    },
    callback,
  });
};

export default sendDesignFormatChangePostMessageOperation;
