import { DesignData } from 'src/store/design/types';
import useIntegrationLayer from '../hook/useIntegrationLayer';
import gtmSendEvent from './gtmSendEvent';
import lowDpiWarningsExists from '../design/lowDpiWarningsExists';

let alreadySent = false;

export const gtmSendLowDpiWarningEvent = (data: DesignData, productUid = '', productTypeId = 0) => {
  if (alreadySent) return;

  const lowDpiWarningFound = lowDpiWarningsExists(data);

  if (!lowDpiWarningFound) return;

  const integrationLayer = useIntegrationLayer();
  alreadySent = true;

  gtmSendEvent({
    event: 'low_dpi_flagged',
    eventCategory: 'Low DPI Warning',
    eventLabel: `${integrationLayer.categoryName} - ${integrationLayer.locale}`,
    locale: integrationLayer.locale,
    categoryName: integrationLayer.categoryName,
    isMobile: integrationLayer.isMobile,
    productUid,
    productTypeId,
  });
};

export default gtmSendLowDpiWarningEvent;
