import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { DesignData } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getDesignContentHash from 'src/util/design/getDesignContentHash';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import {
  setDesignIsSavingAction,
  setDesignIdAction,
  setDesignIsPublicAction,
  setDesignIsLoadedAction, setEncryptedDesignIdAction,
} from 'src/store/design/slice';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';
import { wallpaperProductTypeIds } from 'src/api/productTypes';
import getProductId from '../selector/getProductId';
import getQuantity from '../selector/getQuantity';

const addCustomerDesignOperation = (categoryId: number, productTypeId: number, structure: DesignData, encPublicDesignId?: string, productId?: number) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const productId = getProductId(store) || 1;
  if (isDesignSaving(store)) {
    return;
  }
  const {
    clientPlatform,
    productTypeId,
  } = useIntegrationLayer();
  const isWallpaperProduct = wallpaperProductTypeIds.indexOf(productTypeId) !== -1;
  const quantity = getQuantity(store);
  dispatch(setDesignIsSavingAction(true));
  let response;
  try {
    response = (await opApiRequest(
      endpoints.CUSTOMER_DESIGN_V3_ADD,
      {
        encPublicDesignId,
        categoryId,
        productId,
        structure,
        isDesignCompleted: true,
        clientPlatform,
        contentHash: getDesignContentHash(structure),
      },
      'data',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignAddV3Response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot add customer design, exception: ', e);
  }

  if (!response) {
    // eslint-disable-next-line no-console
    console.error('Cannot add customer design');
  }

  if (response) {
    dispatch(setDesignIdAction(response.customerDesignId));
    dispatch(setEncryptedDesignIdAction(response.encryptedDesignId));
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '', `/designer?designId=${response.customerDesignId}&qty=${quantity}`);
    dispatch(setDesignIsPublicAction(false));
    dispatch(setDesignIsLoadedAction(true));
    isWallpaperProduct && dispatch(fetchPricesOperation(categoryId, [productId], true));
  }

  dispatch(setDesignIsSavingAction(false));
};

export default addCustomerDesignOperation;
