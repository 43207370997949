import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Popup from 'src/component/Popup';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import Button from '../Button';
import styles from './index.module.scss';

const UpdateDesignWarningPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closePopup = () => {
    dispatch(hidePopupOperation());
  };

  return (
    <Popup
      popupId="UpdateDesignWarningPopup"
      title={t('txt_error_saving_design')}
      onClosePopup={closePopup}
    >
      <div className={styles.popupContent}>
        <div>{t('txt_try_again_later')}</div>
        <div className={styles.buttonGroup}>
          <Button
            onClick={closePopup}
            withPadding
            primary
            className={styles.withFrame}
            text={t('txt_ok')}
          />
        </div>
      </div>
    </Popup>
  );
};

export default UpdateDesignWarningPopup;
