import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';

const handleEditorAnyClickOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(setOpenedDropdownMenuOperation(undefined));
};

export default handleEditorAnyClickOperation;
