import { createSlice } from '@reduxjs/toolkit';
import setAppBackUrlReducer from 'src/store/app/reducer/setAppBackUrlReducer';
import setAppBasketUrlReducer from 'src/store/app/reducer/setAppBasketUrlReducer';
import setAppDraftsUrlReducer from 'src/store/app/reducer/setAppDraftsUrlReducer';
import setAppEditorModeReducer from 'src/store/app/reducer/setAppEditorModeReducer';
import setAppFeaturesReducer from 'src/store/app/reducer/setAppFeaturesReducer';
import setAppForwardUrlReducer from 'src/store/app/reducer/setAppForwardUrlReducer';
import setAppIsMobileReducer from 'src/store/app/reducer/setAppIsMobileReducer';
import setOpenedDropdownMenuReducer from 'src/store/app/reducer/setOpenedDropdownMenuReducer';
import { AppState, EDITOR_MODE } from 'src/store/app/types';
import showBackdropReducer from 'src/store/app/reducer/showBackdropReducer';
import showPopupReducer from 'src/store/app/reducer/showPopupReducer';
import hidePopupReducer from 'src/store/app/reducer/hidePopupReducer';
import registerCustomerReducer from 'src/store/app/reducer/registerCustomerReducer';

export const appInitialState: AppState = {
  backUrl: undefined,
  forwardUrl: undefined,
  draftsUrl: undefined,
  basketUrl: undefined,
  isMobile: false,
  editorMode: EDITOR_MODE['create-new'],
  features: [],
  openedDropdownMenu: undefined,
  isBackdropVisible: false,
  appStartTime: new Date().getTime(),
  appSessionId: Math.floor(Math.random() * 1000000),
  customerActionsChain: [],
};

export const slice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setAppBackUrlAction: setAppBackUrlReducer,
    setAppForwardUrlAction: setAppForwardUrlReducer,
    setAppEditorModeAction: setAppEditorModeReducer,
    setAppDraftsUrlAction: setAppDraftsUrlReducer,
    setAppBasketUrlAction: setAppBasketUrlReducer,
    setAppIsMobileAction: setAppIsMobileReducer,
    setOpenedDropdownMenuAction: setOpenedDropdownMenuReducer,
    setAppFeaturesAction: setAppFeaturesReducer,
    showBackdropAction: showBackdropReducer,
    showPopupAction: showPopupReducer,
    hidePopupAction: hidePopupReducer,
    registerCustomerAction: registerCustomerReducer,
  },
});

export const {
  setAppBackUrlAction,
  setAppEditorModeAction,
  setAppForwardUrlAction,
  setAppDraftsUrlAction,
  setAppBasketUrlAction,
  setAppIsMobileAction,
  setOpenedDropdownMenuAction,
  setAppFeaturesAction,
  showBackdropAction,
  showPopupAction,
  hidePopupAction,
  registerCustomerAction,
} = slice.actions;

const app = slice.reducer;
export default app;
