import useIntegrationLayer from '../hook/useIntegrationLayer';

export const gtmLowDPIWarningEvents = (eventType: 'show' | 'accept' | 'reject', productUid = '', productTypeId = 0) => {
  const integrationLayer = useIntegrationLayer();
  let event = '';
  switch (eventType) {
    case 'show': event = 'Show'; break;
    case 'accept': event = 'Click - Accept'; break;
    case 'reject': event = 'Click - Reject'; break;
    default:
  }

  return {
    event,
    eventCategory: 'Low DPI Warning',
    eventLabel: `${integrationLayer.categoryName} - ${integrationLayer.locale}`,
    isMobile: integrationLayer.isMobile,
    productUid,
    productTypeId,
  };
};

export default gtmLowDPIWarningEvents;
