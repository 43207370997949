import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setOpenedDropdownMenuAction } from '../slice';

const setOpenedDropdownMenuOperation = (dropdown?: string) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(setOpenedDropdownMenuAction(dropdown));
};

export default setOpenedDropdownMenuOperation;
