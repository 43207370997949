import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import i18n from 'src/i18n';

const sendEmptyProductWarningOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  window.dataLayer.push({
    event: 'Pop-up click',
    category: 'Ecommerce',
    action: 'Pop-up click',
    editorVersion: 'New',
    label: 'Add non-edited to Basket',
  });

  sendPostMessage({
    type: MESSAGE_TYPE['editor.requestUserConfirmation'],
    data: {
      popupTitle: i18n.t('opeh_warning'),
      textLines: [i18n.t('txt_apparel_editor_button_blank_pop_up_add_design')],
      options: [
        {
          title: i18n.t('txt_apparel_editor_button_blank_pop_up_continue_editing'),
        },
      ],
    },
  });
};

export default sendEmptyProductWarningOperation;
