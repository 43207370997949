import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import layoutSchemas from 'src/util/layoutSchemas/layoutSchemas';
import { setLayoutSchemaSetNameAction } from 'src/store/editor/slice';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { phonecaseProductTypeIds, studioSelectionProductTypeIds, wallpaperProductTypeIds } from 'src/api/productTypes';

const sendLayoutSchemasPostMessage = (layoutSchemaSet: string, addEmptyLayouts = false) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const integrationLayer = useIntegrationLayer();
  let layoutSchemaSetToUse = layoutSchemaSet;
  const isStudioSelectionProduct = [...studioSelectionProductTypeIds, ...wallpaperProductTypeIds].indexOf(integrationLayer.productTypeId) !== -1;
  const isPhoneCaseProduct = [...phonecaseProductTypeIds].indexOf(integrationLayer.productTypeId) !== -1;

  if (isStudioSelectionProduct) {
    layoutSchemaSetToUse = 'studio-selection';
  }

  if (isPhoneCaseProduct) {
    layoutSchemaSetToUse = 'phone-case';
  }

  dispatch(setLayoutSchemaSetNameAction(layoutSchemaSetToUse));
  sendPostMessage({
    type: MESSAGE_TYPE['layouts.setSchemas'],
    data: addEmptyLayouts ? [...layoutSchemas.empty, ...layoutSchemas[layoutSchemaSetToUse]] : layoutSchemas[layoutSchemaSetToUse],
  });
};

export default sendLayoutSchemasPostMessage;
