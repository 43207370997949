import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { Feature, MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import sendEnableDesignTrackChangesPostMessageOperation from 'src/store/editor/operation/sendEnableDesignTrackChangesPostMessageOperation';
import getEditorBackgroundColor from 'src/store/editor/selector/getEditorBackgroundColor';
import { wallpaperProductTypeIds } from 'src/api/productTypes';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import mapLocaleToEditorSupported from 'src/util/mapLocaleToEditorSupported';
import isFeatureEnabled from '../selector/isFeatureEnabled';

const sendEditorSettingsPostMessageOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const integrationLayer = useIntegrationLayer();
  const isWallpaperProduct = wallpaperProductTypeIds.indexOf(integrationLayer.productTypeId) !== -1;

  const settings = {
    enableMirroring: isFeatureEnabled(store, Feature.enableMirroring),
    hideAddonsSearch: !isFeatureEnabled(store, Feature.showClipArtSearch),
    backgroundColor: getEditorBackgroundColor(store),
    allowShowRollerStripesChange: isWallpaperProduct,
    allowShowRulersChange: isWallpaperProduct,
    showWallpaperDimensionsInputInFormats: isWallpaperProduct,
    autoApplyUploadedImages: true,
    flagNewTextElementsAsSample: true,
  };

  sendPostMessage({
    type: MESSAGE_TYPE['editor.setEditorHostSettings'],
    data: settings,
  });

  sendPostMessage({
    type: MESSAGE_TYPE['editor.setLocale'],
    data: mapLocaleToEditorSupported(integrationLayer.locale),
  });

  // Enable design structure tracking to catch changes of mirroring property
  // in design structure, due to hard coded mirroring selector on the API editor side
  if (isFeatureEnabled(store, Feature.enableMirroring)) {
    dispatch(sendEnableDesignTrackChangesPostMessageOperation(true));
  }
};

export default sendEditorSettingsPostMessageOperation;
