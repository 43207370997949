import { Store } from 'src/store/index';
import dpiWarningsExists from 'src/util/design/dpiWarningsExists';
import getIsAgreeWithLowDPI from './getIsAgreeWithLowDPI';
import getStructure from './getStructure';
import isLowDPIWarningEnabled from './isLowDPIWarningEnabled';

const shouldDisplayLowDPIWarning = (store: Store) => (
  isLowDPIWarningEnabled(store) && dpiWarningsExists(getStructure(store)) && !getIsAgreeWithLowDPI(store));

export default shouldDisplayLowDPIWarning;
