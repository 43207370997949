import { PayloadAction } from '@reduxjs/toolkit';
import { UpsellState } from 'src/store/upsell/types';

const setBeforeAddToBasketUpsellWasShownReducer = (
  state: UpsellState, action: PayloadAction<boolean>,
) => {
  state.isBeforeAddToBasketUpsellWasShown = action.payload;
};

export default setBeforeAddToBasketUpsellWasShownReducer;
