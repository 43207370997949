import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Button from 'src/component/Button';
import IconBasket from 'src/component/Icon/IconBasket';
import { Store } from 'src/store';
import sendRequestDesignDataPostMessageToUpdateOrderItemOperation
  from 'src/store/app/operation/sendRequestDesignDataPostMessageToUpdateOrderItemOperation';
import { setAgreeWithLowDPIAction } from 'src/store/design/slice';
import getEditorMode from 'src/store/app/selector/getEditorMode';
import { EDITOR_MODE } from 'src/store/app/types';
import getCanDesignBeSaved from 'src/store/design/selector/getCanDesignBeSaved';
import getOrderItemId from 'src/store/design/selector/getOrderItemId';
import hasDesignStructureChanged from 'src/store/design/selector/hasDesignStructureChanged';
import isDesignLoaded from 'src/store/design/selector/isDesignLoaded';
import isDesignLoading from 'src/store/design/selector/isDesignLoading';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import isPublicDesign from 'src/store/design/selector/isPublicDesign';

interface OwnProps {
  showLabel: boolean;
}

interface Props extends OwnProps {
  isCustomerDesign: boolean;
  isSaving: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  canSave: boolean;
  basketUrl?: string;
  hasDesignChanged: boolean;
  editorMode: EDITOR_MODE;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const SaveEditedFromBasketButton = (
  {
    dispatch,
    isCustomerDesign,
    isSaving,
    isLoading,
    isLoaded,
    canSave,
    hasDesignChanged,
    editorMode,
    showLabel,
  }: Props,
) => {
  const { t } = useTranslation();

  if (
    !isCustomerDesign
    || !isLoaded
    || !hasDesignChanged
    || isLoading
    || editorMode !== EDITOR_MODE['edit-from-basket']
  ) {
    return null;
  }

  const onClick = () => {
    dispatch(setAgreeWithLowDPIAction(false));
    dispatch(sendRequestDesignDataPostMessageToUpdateOrderItemOperation());
  };

  return (
    <Button
      onClick={onClick}
      withPadding
      primary
      disabled={isSaving || !canSave}
      icon={<IconBasket />}
      text={showLabel ? t('opeh_top-bar_save-to-basket') : null}
    />
  );
};

const mapStateToProps = (store: Store) => ({
  isSaving: isDesignSaving(store),
  isLoading: isDesignLoading(store),
  isLoaded: isDesignLoaded(store),
  isCustomerDesign: !isPublicDesign(store),
  hasDesignChanged: hasDesignStructureChanged(store),
  editorMode: getEditorMode(store),
  orderItemId: getOrderItemId(store),
  canSave: getCanDesignBeSaved(store),
});

export default connect(mapStateToProps)(SaveEditedFromBasketButton);
