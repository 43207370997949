import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const sendEditorAddonsLogoPostMessageOperiation = (callback?: any) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const { opApiBaseUrl } = useIntegrationLayer();
  sendPostMessage({
    type: MESSAGE_TYPE['addons.setAddonsAttributionImage'],
    data: {
      url: `${opApiBaseUrl}/s/assets/images/addons/shutterstockLogo.svg`,
      backgroundSize: 'auto 45%',
    },
    callback,
  });
};

export default sendEditorAddonsLogoPostMessageOperiation;
