import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import apiTypes from 'src/api/optimalprint-sdk.d';
import endpoints from 'src/api/opEndpoints';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import getForwardUrl from 'src/store/app/selector/getForwardUrl';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getDesignId from 'src/store/design/selector/getDesignId';
import getProductUid from 'src/store/design/selector/getProductUid';
import getQuantity from 'src/store/design/selector/getQuantity';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { setDesignIsSavingAction, setDesignIsLoadingAction, setDesignOrderItemIdAction } from 'src/store/design/slice';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';
import fetchFrameUpsellDataOperation from 'src/store/upsell/operation/fetchFrameUpsellDataOperation';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import getFrameUpsellData from 'src/store/upsell/selector/getFrameUpsellData';
import getStructure from 'src/store/design/selector/getStructure';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import isDesignHasEmptyContent from 'src/util/design/isDesignHasEmptyContent';
import trackEventOperation from 'src/store/addon/operation/trackEventOperation';
import shouldDisplayLowDPIWarning from 'src/store/design/selector/shouldDisplayLowDPIWarning';
import shouldDisplayUpsellBeforeAddToBasket from 'src/store/design/selector/shouldDisplayUpsellBeforeAddToBasket';
import { displayUpsellBeforeAddToBasketAction } from 'src/store/upsell/slice';
import sendEmptyProductWarningOperation from './sendEmptyProductWarningOperation';
import showPopupOperation from './showPopupOperation';
import { Feature } from '../types';

function getUrlParams(): Record<string, string> {
  const params: Record<string, string> = {};
  new URL(window.location.href).searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

const addToBasketOperation = () => async (dispatch: ThunkDispatch<Store, undefined, AnyAction>, getState: () => Store) => {
  const store = getState();
  const designData = getStructure(store);

  if (isDesignSaving(store)) {
    return;
  }

  if (isFeatureEnabled(Feature.BlockPopupWhenEmptyDesign) && designData && isDesignHasEmptyContent(designData)) {
    dispatch(sendEmptyProductWarningOperation());
    return;
  }

  if (isDesignHasEmptyContent(designData)) {
    dispatch(trackEventOperation('customerAttemptAddToCartEmptyDesign'));
  }

  if (shouldDisplayLowDPIWarning(store)) {
    dispatch(showPopupOperation('LowDPIWarningPopup'));
    return;
  }

  if (shouldDisplayUpsellBeforeAddToBasket(store)) {
    dispatch(displayUpsellBeforeAddToBasketAction(true));
    return;
  }

  try {
    dispatch(setDesignIsLoadingAction(true));
    dispatch(setDesignIsSavingAction(true));
    const designUpdateResult = await dispatch(updateCustomerDesignOperation());
    if (!designUpdateResult) {
      dispatch(showPopupOperation('UpdateDesignWarningPopup'));
      dispatch(trackEventOperation('customerDesignUpdateFailed'));
      throw new Error('Error updating customer design');
    }

    const urlParams = getUrlParams();

    const { us, preset, source } = urlParams;

    const orderData = (await opApiRequest(
      endpoints.CART_V1_DESIGN_ADD,
      {
        quantity: getQuantity(store),
        customerDesignId: getDesignId(store),
        gtm: {
          us,
          preset,
          source,
        },
      },
      'data',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Cart.V1.IItemAddV1Response;

    orderData?.orderItemId && dispatch(setDesignOrderItemIdAction(orderData.orderItemId));

    const bundledProducts = getProductBundleSelectedProducts(store);

    for (let i = 0; i < bundledProducts.length; i += 1) {
      if (bundledProducts[i].productId > 0) {
      // eslint-disable-next-line no-await-in-loop
        await opApiRequest(
          endpoints.CART_V1_PRODUCT_ADD,
          {
            quantity: getQuantity(store),
            customerDesignId: getDesignId(store),
            productId: bundledProducts[i].productId,
            categoryId: getCategoryId(store),
            includeAnalytics: true,
            bundleOwnerOrderItemId: orderData.orderItemId,
          },
          'data',
          'POST',
        );
      }
    }

    if (orderData) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, '', `/designer?order_item_id=${orderData.encOrderItemId}&back_url=${window.integrationLayer.forwardUrl}`);
    }

    const { categoryName } = useIntegrationLayer();
    const productUid = getProductUid(store);

    window.dataLayer.push({
      event: 'editorAddToBasket',
      category: categoryName,
      product: productUid,
      editorVersion: 'New',
    });

    const bundledProductNotSelected = bundledProducts.length === 0 || bundledProducts[0].productId === 0;
    if (bundledProductNotSelected) {
      await dispatch(fetchFrameUpsellDataOperation());
      const bundledProductsAvailable = getFrameUpsellData(getState());
      if (bundledProductsAvailable) {
        dispatch(showPopupOperation('SelectFramePopup'));
        dispatch(setDesignIsLoadingAction(false));
        dispatch(setDesignIsSavingAction(false));
        return;
      }
    }
    dispatch(goToOptimalprintOperation(getForwardUrl(store)));
  } catch (e) {
    dispatch(setDesignIsLoadingAction(false));
    dispatch(setDesignIsSavingAction(false));
    // eslint-disable-next-line no-console
    console.error('Cannot update customer order item quantity', e);
  }
};

export default addToBasketOperation;
