import { AnyAction } from 'redux';
import { renderToString } from 'react-dom/server';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { EditorTabNames, Feature, MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import conditionalList from 'src/util/feature/conditionalList';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import i18n from 'src/i18n';
import ApparelIcon from 'src/component/Icon/IconApparel';
import { apparelProductTypeIds, studioSelectionProductTypeIds } from 'src/api/productTypes';

const sendSetEditorTabsPostMessage = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const integrationLayer = useIntegrationLayer();
  const isStudioSelectionProduct = studioSelectionProductTypeIds.indexOf(integrationLayer.productTypeId) !== -1;

  const supportedTabs: any[] = conditionalList({
    [EditorTabNames.ADDONS]: isFeatureEnabled(Feature.supportClipArt),
    [EditorTabNames.GALLERY]: !isStudioSelectionProduct,
    [EditorTabNames.TEXTS]: isFeatureEnabled(Feature.allowTexts),
    [EditorTabNames.LAYERS]: isFeatureEnabled(Feature.allowLayers),
    [EditorTabNames.LAYOUTS]: isFeatureEnabled(Feature.allowLayouts),
  });

  if (isFeatureEnabled(Feature.allowFormats)) {
    if (apparelProductTypeIds.indexOf(integrationLayer.productTypeId) !== -1) {
      supportedTabs.push({
        id: EditorTabNames.FORMATS,
        title: i18n.t('editor-product'),
        iconName: 'formats',
        iconSVG: renderToString(ApparelIcon()),
      });
    } else {
      supportedTabs.push(EditorTabNames.FORMATS);
    }
  }

  supportedTabs.push(...conditionalList({
    [EditorTabNames.SETTINGS]: isFeatureEnabled(Feature.allowSettings),
    [EditorTabNames.WARNINGS]: true,
  }));

  sendPostMessage({
    type: MESSAGE_TYPE['editor.setSidebarTabs'],
    data: supportedTabs,
  });

  const { isMobile } = useIntegrationLayer();
  if (!isMobile) {
    sendPostMessage({
      type: MESSAGE_TYPE['editor.setSidebarActiveTabName'],
      data: EditorTabNames.GALLERY,
    });
  }
};

export default sendSetEditorTabsPostMessage;
